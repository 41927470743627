/* eslint-disable max-len */
import React, { ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  SelectOld,
  SearchIcon,
  SelectStyled,
  TextareaNew,
  Divider,
  SelectStyledMulti,
  WarningIcon,
  Toggle,
  FormErrorMessage,
  ClearableTrigger,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import { SubscriberOption } from "@components/_common/SubscriberOption/SubscriberOption";
import { SingleMail } from "@components/_common/SingleMail/SingleMail";
import { HumanReview } from "@components/_common/HumanReview/HumanReview";

import { usersSelector } from "@store/users/selectors";
import { meSelector } from "@store/me/selectors";
import { workflowRiskOptionsSelector } from "@store/workflows/selectors";

import s from "./EmailAlertWorkflowAction.module.less";

import {
  getRiskLevelOptions,
  getSubscribersFilterOptions,
  ADDITIONAL_TEXT_MAXLEN,
} from "./EmailAlertWorkflowAction.helpers";
import { FormInstance } from "antd";
import { IClearableTriggerProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IWorkflowEmailAlertAction } from "@store/workflows/types";

import { EmailIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IEmailAlertWorkflowAction {
  form: FormInstance;
}

export const EmailAlertWorkflowAction: React.FC<IEmailAlertWorkflowAction> = ({ form }) => {
  const { t } = useTranslation();
  const values = (form.getFieldValue("action") || {}) as IWorkflowEmailAlertAction;

  const users = useSelector(usersSelector);
  const me = useSelector(meSelector);
  const workflowRiskOptions = useSelector(workflowRiskOptionsSelector);

  const isManagedClient = !!me?.client?.is_managed;
  const notificationCanBeManaged = !!me?.is_notification_can_be_managed;
  const hasRiskIdentification = !!values?.risk_identification?.risked;

  useEffect(() => {
    if (hasRiskIdentification && !values?.risk_identification?.risk_lvl) {
      form.setFieldsValue({
        action: {
          risk_identification: {
            risk_lvl: "low",
          },
        },
      });
    } else if (!hasRiskIdentification) {
      form.setFieldsValue({
        action: {
          risk_identification: undefined,
        },
      });
    }
    // eslint-disable-next-line
  }, [hasRiskIdentification]);

  const onToggleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    form.setFieldsValue({
      action: {
        [name]: checked,
      },
    });
  };

  const onImpactAreaChange = (val: string) => {
    const impactAreaValue = values.risk_identification?.impact_area;
    const newValue = impactAreaValue?.includes(val)
      ? impactAreaValue?.filter(i => i !== val)
      : [...(impactAreaValue || []), val];

    form.setFieldsValue({
      action: {
        risk_identification: {
          impact_area: newValue?.length ? newValue : undefined,
        },
      },
    });
  };

  const onRiskSectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    form.setFieldsValue({
      action: {
        risk_identification: {
          risked: checked,
        },
      },
    });
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    const impactAreaValue = values.risk_identification?.impact_area;
    if (!impactAreaValue?.length) {
      return ClearableTrigger(tooltipProps);
    }
    const tooltipElement = workflowRiskOptions?.impact_area
      ?.filter(opt => impactAreaValue?.includes(opt.id))
      .map(group => (
        <span className={s.bbDisplayBlock} key={group.id}>
          {group.label}
        </span>
      ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () =>
        form.setFieldsValue({
          action: {
            risk_identification: {
              impact_area: undefined,
            },
          },
        }),
      ...tooltipProps,
    });
  };

  return (
    <div className={s.bbEmailAlertAction}>
      <div className={s.bbEmailAlertActionConfig}>
        <div className={s.bbEmailAlertActionForm}>
          <div className={s.bbEmailAlertActionSection}>
            <div>
              <div className={s.bbEmailAlertActionSectionIcon}>
                <EmailIcon />
              </div>
              <span> {t("pages:workflows:form:actions:email_alert:tabsLabel")}</span>
            </div>
          </div>
          <Form.Item
            name={["action", "user_ids"]}
            className={s.bbFormItemUsers}
            label={t("components:notificationsPanel:modals:create:form:users:label")}
            rules={[
              {
                required: true,
                message: (
                  <FormErrorMessage>
                    {t("components:notificationsPanel:modals:create:form:users:message")}
                  </FormErrorMessage>
                ),
              },
            ]}
          >
            <SelectOld
              showArrow
              size="large"
              tabIndex={-1}
              mode="multiple"
              maxTagTextLength={20}
              className={s.bbSubscriberSelect}
              data-cy="email-alert-subscribers-select"
              placeholder={t("generic:search")}
              maxTagCount={2}
              suffixIcon={
                <span>
                  <SearchIcon />
                </span>
              }
              options={users.map(user => ({
                value: user.id,
                label: <SubscriberOption user={user} />,
              }))}
              filterOption={(input, option) => getSubscribersFilterOptions(users, input, option)}
            />
          </Form.Item>
          <Form.Item name="is_single_email">
            <SingleMail
              name="is_single_email"
              checked={!!values.is_single_email}
              onChange={onToggleChange("is_single_email")}
            />
          </Form.Item>
          {isManagedClient && notificationCanBeManaged && (
            <div className={s.bbHumanReviewCheck}>
              <Form.Item name="is_managed">
                <HumanReview
                  checked={!!values?.is_managed}
                  onChange={onToggleChange("is_managed")}
                />
              </Form.Item>
            </div>
          )}
          <Divider className={s.bbDivider} />
          <div
            className={classNames(s.bbRiskSection, {
              [s.bbRiskSectionHidden]: !values?.risk_identification?.risked,
            })}
          >
            <div className={s.bbEmailAlertActionSection}>
              <div>
                <div className={s.bbEmailAlertActionSectionIcon}>
                  <WarningIcon />
                </div>
                <span>
                  {t("pages:workflows:form:actions:email_alert:risk_identification:potentialRisk")}
                </span>
              </div>
              <Toggle
                id="risk_identification"
                checked={hasRiskIdentification}
                onChange={onRiskSectionChange}
              />
            </div>
            <div className={s.bbRiskLevelSelect}>
              <Form.Item
                name={["action", "risk_identification", "risk_lvl"]}
                required
                label={t("pages:workflows:form:actions:email_alert:risk_identification:riskLevel")}
              >
                <SelectStyled
                  options={getRiskLevelOptions(workflowRiskOptions?.risk_lvl)}
                  prefixEl={
                    getRiskLevelOptions(workflowRiskOptions?.risk_lvl).find(
                      i => i.id === values?.risk_identification?.risk_lvl,
                    )?.leftLabel
                  }
                  dropdownMenuClassName={s.bbDropdownMenu}
                  wrapperClassName={s.bbRiskLevelSelectWrapper}
                  dropdownMatchSelectWidth
                />
              </Form.Item>

              <Form.Item
                name={["action", "risk_identification", "impact_area"]}
                label={t("pages:workflows:form:actions:email_alert:risk_identification:impactArea")}
              >
                <SelectStyledMulti
                  options={workflowRiskOptions?.impact_area}
                  dropdownMenuClassName={s.bbDropdownMenu}
                  TriggerVariation={TriggerWithTooltip}
                  placeholder={t("generic:all")}
                  onChange={onImpactAreaChange}
                  dropdownMatchSelectWidth
                />
              </Form.Item>
            </div>
            <Form.Item
              name={["action", "risk_identification", "additional_instructions"]}
              label={t(
                "pages:workflows:form:actions:email_alert:risk_identification:additionalInstructions",
              )}
              className={s.bbEmailAlertTextAreaForm}
              rules={[
                {
                  max: ADDITIONAL_TEXT_MAXLEN,
                  message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
                },
              ]}
            >
              <TextareaNew className={s.bbEmailAlertTextArea} maxLength={ADDITIONAL_TEXT_MAXLEN} />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};
