import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import loadable from "@loadable/component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Form, Grid } from "antd";
import UpgradePlanCTA from "./UpgradePlanCTA";
import FilterProvider from "./FilterProvider";
import { ChangeClient } from "./ChangeClient";
import AIInsightsModal from "../AIInsightsModal";
import { ChangeClientTitle } from "./ChangeClient";
import MobileFiltersModal from "./MobileFiltersModal";
import TrialSubscription from "./TrialSubscription";
import ClientPlanBadge from "../ClientPlanBadge/ClientPlanBadge";
import { Notifications } from "./notifications/Notifications";
import AppHeaderKnowledgeBaseWidget from "./AppHeaderKBWidget";
import AppFiltersContainer from "@containers/AppFiltersContainer";
import { AppSidebar, AppLayout, ExtraMenuItemDivider } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postSavedFilterIdSelector,
  postSavedFilterPendingSelector,
  postSavedFilterSuccessfulSelector,
  predefinedFiltersSelector,
} from "@store/savedFilters/selectors";
import {
  filterDataSelector,
  filtersOpenSelector,
  savedFilterValueSelector,
} from "@store/filters/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { isAIInsightsModalExpandedSelector } from "@store/aiInsights/selectors";
import { aiOnboardingAvailable, aiOnboardingModalExpanded } from "@store/accounts/selector";
import { clientDataSelector, meSelector } from "@store/me/selectors";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import {
  deleteSavedFilterAction,
  postSavedFilterAction,
  putSavedFilterAction,
  resetSavedFiltersDataAction,
} from "@store/savedFilters/actions";
import { toggleOpenAIModalAction } from "@store/aiInsights/actions";
import { getNotificationsAction } from "@store/notifications/actions";
import { getModuleSectionsListAction } from "@store/modules/actions";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { saveFilterAction, toggleOpenFiltersPanelAction } from "@store/filters/actions";
import { checkOnboardingAIAction, toggleAIOnboardingAction } from "@store/accounts/actions";

import s from "./AppLayoutContainer.module.less";

import {
  debounce,
  IExtraItem,
  IFormHandler,
  IAppLayout,
  MenuItemDecorator,
  AppSidebarProvider,
  IFormFilterRequest,
  IPostSavedFilterBodyProps,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { getCurrentModuleName } from "@utils/paths";
import { getSubFeatureName } from "@utils/feature";
import { getFetchValuesFieldName } from "@utils/filters";
import { useTrialSubscription } from "@utils/useTrialSubscription";
import { useSavedFiltersList } from "@utils/useSavedFiltersList";
import { useFilterContext } from "./FilterContext";
import { IAvailableSectionsType } from "@store/modules/types";
import { AIModalStateEnum } from "@store/aiInsights/types";
import { IPutSavedFilterBodyProps } from "@store/savedFilters/types";
import { useGetDefaultFiltersConfig } from "../AppFilter/config";
import { unreadFeatures, MobileMenuIconMap, ModuleIconMap } from "@utils/appSidebar";
import { useMobileMenuItems } from "./useMobileMenuItems";

const AIOnboarding = loadable(() => import("@components/_common/AIOnboarding"));

type IAppLayoutContainerProps = Omit<
  IAppLayout,
  | "MobileModuleIconMap"
  | "unreadFeatures"
  | "mobileMenu"
  | "inboxMobileMenu"
  | "mobileMenuExpandedKey"
  | "onMobileMenuItemClick"
  | "isMobileMenuItemActive"
>;

const AppLayoutContainer: React.FC<IAppLayoutContainerProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;
  const [searchParams] = useSearchParams();
  const FiltersRef = useRef<IFormHandler>(null);
  const [filtersForm] = Form.useForm<IFormFilterRequest>();

  const isTrialSubscription = useTrialSubscription();
  const currentModuleId = getCurrentModuleName();
  const subFeatureName = getSubFeatureName();
  const savedFiltersList = useSavedFiltersList();
  const { onSavedFilterChange } = useFilterContext();
  const defaultFiltersConfig = useGetDefaultFiltersConfig(t, false, filtersForm);
  const {
    mobileMenuItems,
    inboxMobileMenuItems,
    mobileMenuExpandedKey,
    onMobileMenuItemClick,
    isMobileMenuItemActive,
  } = useMobileMenuItems();

  const hasSidebar = getCurrentModuleName() === "insights";
  const isFooterFixed = window.location.pathname.includes("/settings/asset-management/add-assets");

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(
    location.search === "?subscription-modal=true",
  );

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);
  const appFilters = useSelector(filterDataSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const isPostSavedPending = useSelector(postSavedFilterPendingSelector);
  const isPostSuccessful = useSelector(postSavedFilterSuccessfulSelector);
  const postSavedFilterId = useSelector(postSavedFilterIdSelector);
  const isAIInsightsModalExpanded = useSelector(isAIInsightsModalExpandedSelector);
  const isAIOnboardingModalExpanded = useSelector(aiOnboardingModalExpanded);
  const filtersPanelOpen = useSelector(filtersOpenSelector);
  const isAIOnboardingAvailable = useSelector(aiOnboardingAvailable);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[currentModuleId as IAvailableSectionsType];
  const isAIModalOpen = isAIInsightsModalExpanded || isAIOnboardingModalExpanded;

  const setSavedFilterValue = useCallback(
    (id: string | number | null) => dispatch(saveFilterAction(id)),
    [dispatch],
  );

  const getModuleSectionsList = useCallback(
    (module_id: string) => {
      dispatch(getModuleSectionsListAction(module_id));
    },
    [dispatch],
  );

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const hasFilters = useMemo(() => {
    return (
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      (["insights"].includes(getCurrentModuleName()) ||
        ["dashboard", "inbox", "smart-inbox", "transparency"].includes(subFeatureName))
    );
  }, [searchParams, subFeatureName]);

  const isAIModalAvailable =
    getCurrentModuleName() === "insights" &&
    (searchParams.get("section") === "listen-overview" ||
      searchParams.get("section") === "listen-sentiment_details" ||
      searchParams.get("section") === "topics");

  const closeAiModal = useCallback(() => {
    dispatch(toggleOpenAIModalAction(AIModalStateEnum.Closed));
    dispatch(toggleAIOnboardingAction(AIModalStateEnum.Closed));
  }, [dispatch]);

  useEffect(() => {
    if (!isAIModalAvailable) closeAiModal();
  }, [isAIModalAvailable, closeAiModal]);

  useEffect(() => {
    const savedFilterSearchParam = searchParams.get("saved_filter");
    if (savedFilterSearchParam && !savedFilterValue) setSavedFilterValue(savedFilterSearchParam);
  }, [searchParams, savedFilterValue, setSavedFilterValue]);

  useEffect(() => {
    dispatch(getNotificationsAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      localStorage.getItem("isOnboardingModalSkipped") !== "true" &&
      isAIModalAvailable &&
      !!me?.is_bb_user
    )
      dispatch(checkOnboardingAIAction());
  }, [dispatch, isAIModalAvailable, me?.is_bb_user]);

  const toggleFiltersPanel = useCallback(
    (open: boolean) => dispatch(toggleOpenFiltersPanelAction(open)),
    [dispatch],
  );

  const hasSavedFilters = useMemo(
    () =>
      hasSidebar &&
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "report-alerts" &&
      searchParams.get("section") !== "report-profiles" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags",
    [hasSidebar, searchParams],
  );

  const onSubscriptionModalOpen = () => {
    setShowSubscriptionModal(true);
  };

  const decorateExtraItem: MenuItemDecorator = (item: IExtraItem, subMenuItem) => {
    if (item.id === "upgrade-plan") {
      return <UpgradePlanCTA item={item} onSubscriptionModalOpen={onSubscriptionModalOpen} />;
    }

    if (item.id === "change-client") {
      return (
        <>
          {item.includeDividerBefore && <ExtraMenuItemDivider id={item.id} />}
          {subMenuItem(item, <ChangeClientTitle />, <ChangeClient />)}
          {item.includeDividerAfter && <ExtraMenuItemDivider id={item.id} />}
        </>
      );
    }
  };

  return (
    <FilterProvider ref={FiltersRef}>
      <AppSidebarProvider>
        <AppLayout
          {...props}
          mobileMenu={mobileMenuItems}
          mobileMenuExpandedKey={mobileMenuExpandedKey}
          inboxMobileMenu={inboxMobileMenuItems}
          isMobileMenuItemActive={isMobileMenuItemActive}
          onMobileMenuItemClick={onMobileMenuItemClick}
          MobileModuleIconMap={MobileMenuIconMap}
          unreadFeatures={unreadFeatures}
          moduleSections={sections}
          toggleFiltersPanel={toggleFiltersPanel}
          hasSidebar={hasSidebar}
          isFooterFixed={isFooterFixed}
          decorateExtraItem={decorateExtraItem}
        >
          {{
            ...(props.children as object),
            content: <>{props.children.content}</>,
            belowContent: (
              <>
                {hasFilters && (
                  <>
                    {isMobile ? (
                      <MobileFiltersModal ref={FiltersRef} />
                    ) : (
                      <AppFiltersContainer ref={FiltersRef} />
                    )}
                  </>
                )}
                {isAIModalOpen && !filtersPanelOpen && (
                  <div className={s.bbLayoutContentWithAIModal} />
                )}
                {isAIModalAvailable && <AIInsightsModal />}
                {isAIModalAvailable && isAIOnboardingAvailable && <AIOnboarding />}
              </>
            ),
            appSideBar: hasSidebar && (
              <AppSidebar
                hasSavedFilters={hasSavedFilters}
                currentModuleId={currentModuleId}
                savedFiltersList={savedFiltersList}
                onSavedFilterChange={onSavedFilterChange}
                savedFilterValue={savedFilterValue}
                sections={sections}
                getModuleSectionsList={getModuleSectionsList}
                filtersConfig={defaultFiltersConfig}
                autocompleteOptions={autocompleteOptions}
                predefinedFilters={predefinedFilters}
                sanitizeFieldName={getFetchValuesFieldName}
                getAutocompleteOptions={getAutocompleteOptions}
                isPostSavedPending={isPostSavedPending}
                isPostSuccessful={isPostSuccessful}
                postSavedFilterId={postSavedFilterId}
                appFilters={appFilters}
                ModuleIconMap={ModuleIconMap}
                unreadFeatures={unreadFeatures}
                availablePlatformTypes={clientData?.platform_types || []}
                onResetFilters={() => dispatch(resetSavedFiltersDataAction())}
                onDeleteSavedFilter={(id: string) => dispatch(deleteSavedFilterAction(id))}
                onCreateSavedFilter={(payload: IPostSavedFilterBodyProps) =>
                  dispatch(postSavedFilterAction(payload))
                }
                onUpdateSavedFilter={(payload: IPutSavedFilterBodyProps) =>
                  dispatch(putSavedFilterAction(payload))
                }
                showBottomSuffix={!!me?.client?.client_plan}
                bottomSuffix={(isSidebarCollapsed: boolean) =>
                  !!me?.client?.client_plan ? (
                    <ClientPlanBadge
                      type={me?.client?.client_plan}
                      showTitle={!isSidebarCollapsed}
                    />
                  ) : null
                }
              />
            ),
            headerActions: (
              <>
                <AppHeaderKnowledgeBaseWidget />
                <Notifications />
              </>
            ),
            headerActionsPrefix: isTrialSubscription && (
              <TrialSubscription
                showSubscriptionModal={showSubscriptionModal}
                setShowSubscriptionModal={setShowSubscriptionModal}
              />
            ),
            submenuSection: !!me?.client?.client_plan && (
              <ClientPlanBadge type={me.client.client_plan} />
            ),
          }}
        </AppLayout>
      </AppSidebarProvider>
    </FilterProvider>
  );
};

export default AppLayoutContainer;
