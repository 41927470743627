import React from "react";

import { FormErrorMessage } from "@bbdevcrew/bb_ui_kit_fe";

import { RuleObject } from "antd/lib/form";
import { TFunction } from "i18next";

export const optionRequiredValidator = (errMsg: string) => ({
  required: true,
  validator: (_: RuleObject, value: string[]) => {
    if (!value || value.length === 0) {
      return Promise.reject(<FormErrorMessage>{errMsg}</FormErrorMessage>);
    }
    return Promise.resolve();
  },
});

export const messageTypeOptions = (t: TFunction) => [
  { group: t("pages:workflows:form:triggers:reply:messageTypes:ownedPublic") },
  {
    value: "comments",
    label: t("pages:workflows:form:triggers:reply:messageTypes:comments"),
  },
  {
    value: "ad_comments",
    label: t("pages:workflows:form:triggers:reply:messageTypes:ad_comments"),
  },
  { value: "wall_posts", label: t("pages:workflows:form:triggers:reply:messageTypes:wall_posts") },

  { group: t("pages:workflows:form:triggers:reply:messageTypes:ownedPrivate") },
  {
    value: "private_messages",
    label: t("pages:workflows:form:triggers:reply:messageTypes:private_messages"),
  },
  {
    value: "replies_to_stories",
    label: t("pages:workflows:form:triggers:reply:messageTypes:story_replies"),
  },

  { group: t("pages:workflows:form:triggers:reply:messageTypes:earned") },
  { value: "reviews", label: t("pages:workflows:form:triggers:reply:messageTypes:reviews") },
  {
    value: "comment_mention",
    label: t("pages:workflows:form:triggers:reply:messageTypes:comment_mentions"),
  },
  {
    value: "caption_mention",
    label: t("pages:workflows:form:triggers:reply:messageTypes:caption_mentions"),
  },
  {
    value: "quote_tweets",
    label: t("pages:workflows:form:triggers:reply:messageTypes:quote_tweets"),
  },
  {
    value: "replies_to_twitter_mentions",
    label: t("pages:workflows:form:triggers:reply:messageTypes:twitter_mentions"),
  },
];

export const fieldGroups: Record<string, string[]> = {
  platform_types: ["platform_types", "asset_ids", "asset_group_ids"],
  message_types: ["message_types"],
  automation_triggers: [
    "keyword_query",
    "sentiments",
    "languages",
    "comment_tags",
    "review_types",
    "author_ids",
    "author_names",
    "visibility_type",
  ],
  additional_filters: [],
};

export const triggerFields = Object.values(fieldGroups).flatMap(fields => fields);
