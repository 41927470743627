import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "antd";
import WorkflowItem from "./item/WorkflowItem";
import WorkflowCreationModal from "./creationModal/WorkflowCreationModal";
import { Modal, Button, InputWhite, ButtonPermissionGuard } from "@bbdevcrew/bb_ui_kit_fe";

import {
  workflowDeletedSelector,
  workflowDeletingSelector,
  workflowDisabledSelector,
  workflowEditedSelector,
  workflowEnabledSelector,
  workflowCreatedSelector,
  workflowFormDirtySelector,
} from "@store/workflows/selectors";
import { deleteWorkflowAction, getWorkflowsAction } from "@store/workflows/actions";

import { useWorkflows } from "./helpers";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import usePermissions from "@utils/usePermissions";
import { WorkflowActionFnType } from "./item/helpers";
import { IWorkflowItem } from "@store/workflows/types";

import s from "./Workflows.module.less";

import { PlusIcon, SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const WORKFLOW_TOASTS_DURATION = 5_000;

const WorkflowsContent = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const dispatch = useDispatch();

  const permissions = usePermissions();

  const [searchQuery, setSearchQuery] = useState("");
  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkflowItem>();

  const { items: workflowItems, activeCategoryIds } = useWorkflows();

  const workflowWasDisabled = useSelector(workflowDisabledSelector);
  const workflowWasEnabled = useSelector(workflowEnabledSelector);
  const workflowCreated = useSelector(workflowCreatedSelector);
  const workflowEdited = useSelector(workflowEditedSelector);
  const workflowDeleted = useSelector(workflowDeletedSelector);
  const workflowDeleting = useSelector(workflowDeletingSelector);
  const workflowFormDirty = useSelector(workflowFormDirtySelector);

  useEffect(() => {
    if (workflowCreated || workflowEdited || workflowDeleted) {
      dispatch(getWorkflowsAction());
    }
  }, [workflowCreated, workflowEdited, workflowDeleted, dispatch]);

  useEffect(() => {
    if (workflowCreated) {
      setWorkflowModalOpen(false);

      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:creationSuccess:title"),
        message: t("pages:workflows:workflowCreationModal:creationSuccess:description"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowCreated, t]);

  useEffect(() => {
    if (workflowEdited) {
      setWorkflowModalOpen(false);
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:editSuccess"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowEdited, t]);

  useEffect(() => {
    if (workflowDeleted) {
      setDeleteModalOpen(false);
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowDeletionModal:deletionSuccess"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowDeleted, t]);

  useEffect(() => {
    if (workflowWasEnabled) {
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:enablingSuccess:title"),
        message: t("pages:workflows:workflowCreationModal:enablingSuccess:description"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowWasEnabled, t]);

  useEffect(() => {
    if (workflowWasDisabled) {
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:disablingSuccess:title"),
        message: t("pages:workflows:workflowCreationModal:disablingSuccess:description"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowWasDisabled, t]);

  const deleteWorkflow = useCallback(
    (id: string) => dispatch(deleteWorkflowAction(id)),
    [dispatch],
  );

  const onDeleteClick =
    (workflowItem: IWorkflowItem): WorkflowActionFnType =>
    closeActionMenu => {
      setDeleteModalOpen(true);
      setSelectedWorkflow(workflowItem);
      closeActionMenu();
    };

  const onEditClick =
    (workflowItem: IWorkflowItem): WorkflowActionFnType =>
    closeActionMenu => {
      setWorkflowModalOpen(true);
      setSelectedWorkflow(workflowItem);
      closeActionMenu();
    };

  const onConfirmDeleteClick = () => {
    if (selectedWorkflow) deleteWorkflow(selectedWorkflow.id);
  };

  const onCreateClick = () => {
    setSelectedWorkflow(undefined);
    setWorkflowModalOpen(true);
  };

  const filteredWorkflowItems: IWorkflowItem[] = useMemo(() => {
    const filteredByCategory = activeCategoryIds
      ? workflowItems.filter(workflowItem => activeCategoryIds.includes(workflowItem.id))
      : workflowItems;
    return filteredByCategory.filter(w =>
      w.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [activeCategoryIds, workflowItems, searchQuery]);

  return (
    <div
      className={classNames(s.bbWorkflowsContent, {
        [s.bbWorkflowsContentSm]: screens.sm,
        [s.bbWorkflowsContentMd]: screens.md,
        [s.bbWorkflowsContentLg]: screens.lg,
      })}
      data-cy="workflows-content-container"
    >
      <div className={s.bbWorkflowsTopBar}>
        <div className={s.bbWorkflowsSearchInput}>
          <InputWhite
            _size="sm"
            value={searchQuery}
            prefixEl={<SearchIcon />}
            id="workflows-search-input"
            placeholder={t("generic:search")}
            data-cy="workflows-search-input"
            onChange={e => setSearchQuery(e.target.value)}
            wrapperClassName={s.bbWorkflowsSearchInputWrapper}
          />
        </div>
        <ButtonPermissionGuard
          hasPermission={!!permissions["manageWorkflows"]}
          message={t("permissions:createWorkflows")}
        >
          <Button
            _size="sm"
            _type="primary"
            onClick={onCreateClick}
            className={s.bbWorkflowsCreateBtn}
            data-cy="workflows-create-button"
          >
            <PlusIcon /> {t("pages:workflows:createBtn")}
          </Button>
        </ButtonPermissionGuard>
      </div>
      <div
        className={classNames(s.bbWorkflowItems, {
          [s.bbWorkflowItemsSm]: screens.sm,
          [s.bbWorkflowItemsMd]: screens.md,
          [s.bbWorkflowItemsLg]: screens.lg,
          [s.bbWorkflowItemsMobile]: !screens.md,
        })}
      >
        {filteredWorkflowItems.map(workflowItem => (
          <WorkflowItem
            key={workflowItem.id}
            item={workflowItem}
            onEditClick={onEditClick(workflowItem)}
            onDeleteClick={onDeleteClick(workflowItem)}
          />
        ))}
      </div>
      <Modal
        centered
        responsive
        hideFooter
        hideHeader
        width={800}
        open={workflowModalOpen}
        classes={{
          root: s.bbCreationModal,
          modal: s.bbCreationModalContent,
        }}
        onCancel={() => {
          if (workflowFormDirty) {
            setDiscardChangesModalOpen(true);
          } else {
            setWorkflowModalOpen(false);
          }
        }}
        noPadding
      >
        <WorkflowCreationModal item={selectedWorkflow} />
      </Modal>
      <Modal
        responsive
        centered
        width={520}
        open={discardChangesModalOpen}
        title={t("pages:workflows:workflowDiscardChangesModal:title")}
        onCancel={() => {
          setDiscardChangesModalOpen(false);
        }}
        onOk={() => {
          setDiscardChangesModalOpen(false);
          setWorkflowModalOpen(false);
        }}
        confirmLabel={t("pages:workflows:workflowDiscardChangesModal:confirm")}
        cancelLabel={t("pages:workflows:workflowDiscardChangesModal:cancel")}
      >
        <p>{t("pages:workflows:workflowDiscardChangesModal:description")}</p>
      </Modal>
      <Modal
        responsive
        centered
        hideFooter
        width={520}
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        title={t("pages:workflows:workflowDeletionModal:title")}
      >
        <div className={s.bbDeleteModalDescription}>
          {t("pages:workflows:workflowDeletionModal:description")}
        </div>
        <div className={s.bbDeleteModalFooter}>
          <Button _type="secondary" onClick={() => setDeleteModalOpen(false)}>
            {t("generic:cancel")}
          </Button>
          <Button
            _type="danger"
            loading={workflowDeleting}
            onClick={onConfirmDeleteClick}
            data-cy="workflow-item-delete-confirm-button"
          >
            {t("generic:delete")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default WorkflowsContent;
