import { StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import * as actions from "./actions";

import { internalCommentNotes, internalNotes, internalPublishingNotes } from "@utils/paths";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getAuthAPIHeaders } from "@utils/headers";

import { RootState } from "..";

import { IInternalNoteItem, IInternalNotesResponse, InternalNotesActionsType } from "./types";

export const getInternalNotesEpic = (
  action$: Observable<InternalNotesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getInternalNotesAction)),
    switchMap(a => {
      return ajax
        .get<IInternalNotesResponse>(
          `${
            a.payload.isPublishPost ? internalPublishingNotes : internalCommentNotes
          }/${a.payload.id}`,
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getInternalNotesSuccessAction(data.items)),
          catchError(e => handleError(e, actions.getInternalNotesFailureAction)),
        );
    }),
  );

export const postInternalNotesEpic = (
  action$: Observable<InternalNotesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.postInternalNotesAction)),
    switchMap(a => {
      return ajax
        .post<IInternalNoteItem[]>(
          `${internalCommentNotes}/${a.payload.id}`,
          {
            message: a.payload.message,
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(() => actions.getInternalNotesAction({ id: a.payload.id })),
          catchError(e => handleError(e, actions.postInternalNotesFailureAction)),
        );
    }),
  );

export const deleteInternalNoteEpic = (
  action$: Observable<InternalNotesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteInternalNoteAction)),
    switchMap(a => {
      return ajax.delete(`${internalNotes}/${a.payload}`, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        map(() => actions.deleteInternalNoteSuccessAction(a.payload)),
        catchError(e => handleError(e, actions.deleteInternalNoteFailureAction)),
      );
    }),
  );

export const postPublishNotesEpic = (
  action$: Observable<InternalNotesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.postPublishNotesAction)),
    switchMap(a => {
      return ajax
        .post<IInternalNoteItem[]>(
          internalPublishingNotes,
          {
            message: a.payload.message,
            publication_id: a.payload.id,
            post_id: a.payload.id,
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(() => actions.getInternalNotesAction({ id: a.payload.id, isPublishPost: true })),
          catchError(e => handleError(e, actions.postPublishNotesFailureAction)),
        );
    }),
  );
