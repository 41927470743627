import cn from "classnames/bind";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import {
  Button,
  ChevronDownIcon,
  Dropdown,
  RadioGroup,
  SendReplyIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ApprovalSection } from "./ApprovalSection";

import s from "./ReplySendButton.module.less";

import { IApprovalItem, IReplySendButtonProps, SendButtonType } from "./ReplySendButton.types";
import { getApprovalButtonText } from "./ReplySendButton.helpers";

import { ApproveIcon } from "@assets/index";

const cx = cn.bind(s);

export const ReplySendButton: FC<IReplySendButtonProps> = ({
  isPrivateReply,
  isReplyBtnLoading,
  isReplyBtnDisabled,
  onInternalReplyClick,
  replyCapability,
}) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const isMobile = screens.md === false;
  const showApprovalCapability =
    replyCapability?.request_capability?.capability !== "not_applicable";
  const showSendCapability = replyCapability?.send_capability?.capability !== "not_applicable";

  const isApprovalBtnDisabled = replyCapability?.request_capability?.capability === "disabled";
  const isSendBtnDisabled = replyCapability?.send_capability?.capability === "disabled";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sendType, setSendType] = useState<SendButtonType>(
    showSendCapability && !isSendBtnDisabled ? "send_reply" : "send_approval",
  );
  const [value, setValue] = useState<IApprovalItem[]>([]);

  const isDropdownOpenDisabled = isReplyBtnDisabled || isReplyBtnLoading || isApprovalBtnDisabled;
  const showApprovalSection = sendType === "send_approval";

  const onClick = () => {
    if (isReplyBtnDisabled || isReplyBtnLoading) {
      return;
    }
    onInternalReplyClick(
      sendType,
      value.map(v => v.id),
    );
  };

  const onDropdownOpen = (isOpen: boolean) => {
    if (isDropdownOpenDisabled) {
      return;
    }
    setIsDropdownOpen(isOpen);
  };

  const onSendTypChange = (type: SendButtonType) => {
    setSendType(type);
    if (type === "send_reply") {
      setValue([]);
    }
  };

  const onChangeValue = (item: IApprovalItem | null) => {
    if (!item) {
      setValue([]);
    } else if (value.find(val => item.id === val.id)) {
      setValue(value.filter(v => v.id !== item.id));
    } else setValue(prev => [...prev, { id: item.id, label: item.label }]);
  };

  return (
    <div
      className={cx(s.bbReplyBtnWrapper, {
        [s.bbReplyBtnWrapperMobile]: isMobile,
      })}
    >
      <Button
        _size="md"
        _type="primary"
        loading={isReplyBtnLoading}
        onClick={onClick}
        disabled={isReplyBtnDisabled || (isApprovalBtnDisabled && sendType === "send_approval")}
        className={cx(s.bbReplyBtn, { [s.bbReplyBtnWithDropdown]: showApprovalCapability })}
      >
        {showApprovalSection ? <ApproveIcon /> : <SendReplyIcon />}
        {sendType === "send_approval"
          ? getApprovalButtonText(value, t)
          : isPrivateReply
            ? t("components:reply:sendPrivateReply")
            : t("components:reply:sendReply")}
      </Button>
      {showApprovalCapability && (
        <Dropdown
          open={isDropdownOpen}
          setOpen={onDropdownOpen}
          menuClassName={cx(s.bbDropdown, {
            [s.bbDropdownWide]: showApprovalSection,
          })}
          placement="topRight"
          trigger={
            <div
              className={cx(s.bbReplyBtnAction, {
                [s.bbReplyBtnActionDisabled]: isDropdownOpenDisabled,
              })}
            >
              <ChevronDownIcon />
            </div>
          }
        >
          {showSendCapability && !isSendBtnDisabled && (
            <RadioGroup
              value={sendType}
              onChange={val => onSendTypChange(val as SendButtonType)}
              className={s.bbDropdownRadioGroup}
            >
              <RadioGroup.Button _size="sm" key="send_reply" value="send_reply">
                {t("components:reply:sendReply")}
              </RadioGroup.Button>
              <RadioGroup.Button _size="sm" key="send_approval" value="send_approval">
                {t("components:reply:sendApproval")}
              </RadioGroup.Button>
            </RadioGroup>
          )}
          {showApprovalSection && <ApprovalSection value={value} onChange={onChangeValue} />}
        </Dropdown>
      )}
    </div>
  );
};
