import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, Label, NestedList, SearchInput } from "@bbdevcrew/bb_ui_kit_fe";

import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { meSelector } from "@store/me/selectors";

import {
  getAutocompleteOptionsSearchAction,
  updateAutocompleteOptionsAction,
} from "@store/autocomplete/actions";

import s from "./ReplySendButton.module.less";

import { getApprovalUser } from "./ReplySendButton.helpers";
import { IApprovalSectionProps, IUserApprovalOption } from "./ReplySendButton.types";
import { debounce, useSearch, IGetAutocompleteOptionsPayload } from "@bbdevcrew/bb_ui_kit_fe";

export const ApprovalSection = ({ value, onChange }: IApprovalSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleItemClick = (_: unknown, id: string, label?: string) => {
    onChange({ id, label });
  };

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const me = useSelector(meSelector);

  const searchedUsers = autocompleteOptions.user || [];
  const options = getApprovalUser(searchedUsers as IUserApprovalOption[]).filter(
    item => item.id !== me?.id,
  );

  const {
    options: searchedOptions,
    searchTerm,
    setSearchTerm,
  } = useSearch(options, t("generic:search"));

  const getInitialAutocompleteOptions = useCallback(
    payload => {
      dispatch(getAutocompleteOptionsSearchAction(payload));
    },
    [dispatch],
  );

  // eslint-disable-next-line
  const updateAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(updateAutocompleteOptionsAction(payload));
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    const payload: IGetAutocompleteOptionsPayload = {
      field: "user",
      query: searchTerm,
      filter: "users_for_reply_approvals",
    };

    if (searchTerm) updateAutocompleteOptions(payload);
    else getInitialAutocompleteOptions(payload);
  }, [searchTerm, updateAutocompleteOptions, getInitialAutocompleteOptions]);

  return (
    <div className={s.bbApprovalSection}>
      <div className={s.bbApprovalSectionHeader}>
        <span className={s.bbApprovalSectionTitle}>
          {t("components:reply:approve:approvalModal:title")}
        </span>
        <span>{t("components:reply:approve:approvalModal:description")}</span>
      </div>
      <SearchInput
        searchTerm={searchTerm}
        handleSetSearchTerm={setSearchTerm}
        placeholder={t("generic:search")}
      />
      <div className={s.bbApprovalSectionList}>
        <div className={s.bbApprovalSectionItem}>
          <Checkbox
            checked={!value.length}
            onChange={() => {
              onChange(null);
            }}
            _size="sm"
          />
          <Label
            className={s.bbApprovalSectionLabel}
            onClick={() => onChange(null)}
            text={t("components:reply:approve:approvalModal:defaultItem")}
          />
        </div>
        <div className={s.bbApprovalSectionDivider}>
          <div className={s.bbApprovalSectionDividerLine}></div>
          <span>{t("generic:or")}</span>
          <div className={s.bbApprovalSectionDividerLine}></div>
        </div>
        <NestedList
          highlightTerm={searchTerm}
          options={searchedOptions}
          value={value.map(v => v.id)}
          onItemClick={handleItemClick}
        />
      </div>
    </div>
  );
};
