import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Button, Dropdown, SentimentBar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AuthorActionsDropdownMobile.module.less";

import { AuthorActionsDropdownProps, CategoryType } from "../ReplyModal.type";

import {
  CommentIcon,
  HashIcon,
  MentionIcon,
  SendIcon,
  StarIcon,
  DisabledIcon,
  CheckIcon,
  MoreActionsIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const COMMENT_CATEGORY_ICON_MAP = {
  comments: <CommentIcon />,
  private_messages: <SendIcon />,
  public_mentions: <MentionIcon />,
  reviews: <StarIcon />,
  hashtag_mentions: <HashIcon />,
};

const AuthorActionsDropdownMobile = ({
  replyData,
  comment,
  selectedCategory,
  onChangeCategory,
  onUnblockClick,
  onBlockClick,
}: AuthorActionsDropdownProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isBlockedAuthor = replyData.author_detail.block_capability.is_blocked || false;
  const authorActions = replyData.author_detail.categories.map(category => ({
    id: category.id,
    label: category.label,
    icon: COMMENT_CATEGORY_ICON_MAP[category.id as CategoryType],
    count: category.count,
    isActive: selectedCategory?.id === category.id,
    onClick: () => onChangeCategory(category, comment),
  }));
  const blockOrUnblockAction = isBlockedAuthor
    ? {
        id: "unblock_user",
        label: t("components:reply:userActions:unblockUser"),
        icon: <CheckIcon />,
        onClick: onUnblockClick,
      }
    : {
        id: "block_user",
        label: t("components:reply:userActions:blockUser"),
        icon: <DisabledIcon />,
        onClick: onBlockClick,
      };

  return (
    <Dropdown
      useDynamicPlacement
      open={isOpen}
      setOpen={open => setIsOpen(open)}
      menuClassName={s.bbAuthorActionsDropdownMenu}
      trigger={
        <span>
          <Button _size="md" className={s.bbAuthorActionsDropdownTrigger}>
            <MoreActionsIcon />
          </Button>
        </span>
      }
    >
      <div className={s.bbAuthorActionsDropdownContent}>
        {replyData.author_detail.show_sentiment_bar && (
          <SentimentBar height={12} data={replyData.author_detail.comment_stats} />
        )}
        {authorActions.map(action => (
          <div
            key={action.id}
            className={classNames(s.bbAuthorAction, {
              [s.bbAuthorActionActive]: action.isActive,
            })}
            onClick={action.onClick}
          >
            <div className={s.bbAuthorActionLeft}>
              {action.icon}
              <div className={s.bbAuthorActionLabel}>{action.label}</div>
            </div>
            <div className={s.bbAuthorActionRight}>
              {action.isActive && <CheckIcon />}
              <div className={s.bbAuthorActionCount}>{action.count}</div>
            </div>
          </div>
        ))}
        <div className={s.bbAuthorActionDivider} />
        <div className={s.bbAuthorAction} onClick={blockOrUnblockAction.onClick}>
          <div className={s.bbAuthorActionLeft}>
            {blockOrUnblockAction.icon}
            <div className={s.bbAuthorActionLabel}>{blockOrUnblockAction.label}</div>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default AuthorActionsDropdownMobile;
