import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Grid } from "antd";
import { Button, EmojiSelect } from "@bbdevcrew/bb_ui_kit_fe";
import UploadImageButton from "./UploadImageButton/UploadImageButton";
import { SharePostSelector } from "./SharePostSelector/SharePostSelector";
import AIPrompts from "../AIPrompts/AIPrompts";

import { meSelector } from "@store/me/selectors";

import s from "./Reply.module.less";

import { PlatformType } from "@bbdevcrew/bb_ui_kit_fe";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { IReplyInputContainerHeaderProps } from "./Reply.types";

const ReplyInputContainerHeader = ({
  savedReplyModalOpen,
  replyUser,
  repliesSelectorData,
  isTiktokDM,
  canSendDMMedia,
  canSendDMMediaTooltip,
  aIPrompts,
  aIPromptsForm: AIPromptsForm,
  fetchAIPrompts,
  deleteAIPrompt,
  setDefaultAIPrompt,
  getAIPromptSuggestion,
  onGenerateDefaultPromptClick,
  fetchingAIPromptSuggestion,
  onUploadReplyImage,
  onSelectSharePost,
  setReplyImageSizeError,
  onEmojiSelect,
  onSavedReplyClick,
}: IReplyInputContainerHeaderProps) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const isMobile = screens.md === false;

  return (
    <div
      className={classNames(s.bbReplyInputContainerHeader, {
        [s.bbReplyInputContainerHeaderMobile]: isMobile,
      })}
    >
      {!isMobile && (
        <EmojiSelect
          onEmojiSelect={onEmojiSelect}
          platform={replyUser.platform.id as PlatformType}
          useDropdownPortal
        />
      )}
      {isTiktokDM && (
        <>
          <UploadImageButton
            canSendDMMedia={canSendDMMedia}
            canSendDMMediaTooltip={canSendDMMediaTooltip}
            onSelectFile={onUploadReplyImage}
            onFileSizeLimitError={() => setReplyImageSizeError(true)}
          />
          <SharePostSelector
            pageId={repliesSelectorData?.post_preview?.page_id}
            onSelectPost={onSelectSharePost}
          />
        </>
      )}
      {me?.client?.ai_generated_reply_enabled && (
        <AIPrompts
          aIPrompts={aIPrompts}
          aIPromptsForm={AIPromptsForm}
          fetchAIPrompts={fetchAIPrompts}
          deleteAIPrompt={deleteAIPrompt}
          setDefaultAIPrompt={setDefaultAIPrompt}
          getAIPromptSuggestion={getAIPromptSuggestion}
          onGenerateDefaultPromptClick={onGenerateDefaultPromptClick}
          generateDefaultPromptLoading={fetchingAIPromptSuggestion}
          mainBtnTooltipText={t("components:AIPrompts:button:firstPartTooltip")}
          listBtnTooltipText={t("components:AIPrompts:button:secondPartTooltip")}
        />
      )}
      <Button _size="sm" onClick={onSavedReplyClick} className={s.bbSavedReplyButton}>
        {t("generic:savedReplies")}
        {savedReplyModalOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
    </div>
  );
};

export default ReplyInputContainerHeader;
