import React, { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import {
  fetchingCommentHistorySelector,
  getCommentHistorySelector,
} from "@store/comments/selectors";

import { getCommentHistoryAction } from "@store/comments/actions";

import s from "./InternalNotes.module.less";

import { splitFormattedText } from "./InternalNotes.helpers";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ICommentHistoryProps {
  commentId: string;
}

export const CommentHistory: FC<ICommentHistoryProps> = ({ commentId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const commentHistory = useSelector(getCommentHistorySelector);
  const fetchingCommentHistory = useSelector(fetchingCommentHistorySelector);

  const getCommentHistory = useCallback(
    id => {
      dispatch(getCommentHistoryAction(id));
    },
    [dispatch],
  );

  useEffect(() => {
    getCommentHistory(commentId);
  }, [commentId, getCommentHistory]);

  return (
    <div
      className={classNames(s.bbNotesHistory, {
        [s.bbNotesHistoryLoading]: fetchingCommentHistory,
      })}
    >
      {fetchingCommentHistory ? (
        <Loading />
      ) : (
        <>
          <div className={s.bbNotesHistoryList}>
            {commentHistory?.map((item, index) => (
              <div key={index} className={s.bbNotesHistoryItem}>
                <span className={s.bbNotesHistoryDate}>{item.timestamp_pretty}</span>

                <span>{splitFormattedText(item.phrase)}</span>
              </div>
            ))}
          </div>
          {!!commentHistory?.length && (
            <div className={s.bbNotesHistoryInfo}>
              <InformationIcon />
              <span> {t("components:comments:activity:history:info")}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
