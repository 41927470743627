export const DELETE_ACCOUNT = "ACCOUNTS/DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "ACCOUNTS/DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "ACCOUNTS/DELETE_ACCOUNT_FAILURE";

export const CHECK_AI_AVAILABLE = "ACCOUNTS/CHECK_AI_AVAILABLE";
export const CHECK_AI_AVAILABLE_SUCCESS = "ACCOUNTS/CHECK_AI_AVAILABLE_SUCCESS";
export const CHECK_AI_AVAILABLE_FAILURE = "ACCOUNTS/CHECK_AI_AVAILABLE_FAILURE";

export const SET_AI_MODAL_STATE = "ACCOUNTS/SET_AI_MODAL_STATE";

export const GET_ONBOARDING_FIELDS = "ACCOUNTS/GET_ONBOARDING_FIELDS";
export const GET_ONBOARDING_FIELDS_SUCCESS = "ACCOUNTS/GET_ONBOARDING_FIELDS_SUCCESS";
export const GET_ONBOARDING_FIELDS_FAILURE = "ACCOUNTS/GET_ONBOARDING_FIELDS_FAILURE";

export const POST_ONBOARDING_FIELDS = "ACCOUNTS/POST_ONBOARDING_FIELDS";
export const POST_ONBOARDING_FIELDS_SUCCESS = "ACCOUNTS/POST_ONBOARDING_FIELDS_SUCCESS";
export const POST_ONBOARDING_FIELDS_FAILURE = "ACCOUNTS/POST_ONBOARDING_FIELDS_FAILURE";
