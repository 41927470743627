import { t } from "../../../../../../App";
import { ITagOption, IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";

export const tagToOption = (tagOpt: ITagOption) => {
  const option: IObjectOption = {
    id: tagOpt.value,
    label: tagOpt.title,
    options: tagOpt.children?.map(tagToOption),
    isNotSelectable: !!tagOpt.children?.length,
  };

  return option;
};

export const getTagActionTypeOptions = () => [
  {
    label: t("pages:workflows:form:actions:tag:options:custom_tag"),
    id: "custom_tag",
  },
  {
    label: t("pages:workflows:form:actions:tag:options:bb_tag"),
    id: "bb_tag",
  },
];
