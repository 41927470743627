import { IWorkflowItem } from "@store/workflows/types";

export const getInitialValues = (item: IWorkflowItem) => {
  if (item.category !== "email_alert") return item;
  return {
    ...item,
    trigger: {
      ...item.trigger,
      filters: {
        ...(item.trigger?.filters || {}),
        is_post_based: item.email_alert?.is_post_based,
      },
    },
    action: item.email_alert,
  };
};
