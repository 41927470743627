import { IObjectOption, IPost } from "@bbdevcrew/bb_ui_kit_fe";
import { IReplyCapability, IReplyData, IReplyUser } from "@store/replies/types";
import { IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { IInternalReplyFn } from "../MessageList/MessageList.type";
import { SendButtonType } from "./replySendButton/ReplySendButton.types";
import { IAIPromptsResponse } from "@store/repliesAIPrompts/types";
import { IAIPromptFormProps } from "./aiPromptForm/AIPromptForm.type";
import { EmojiData } from "emoji-mart";

export const TAG_OFFSET = 8;
export const DEFAULT_MAX_LENGTH = 1000;

export type AllowedViewsType = "create" | "list" | "edit";
export type ModeType = "modal" | "list";
export type ViewChangeFuncType = (view: AllowedViewsType, tab: string, editId?: string) => void;

export interface IReplierAsset {
  id: string;
  label: string;
  selected: boolean;
}

export interface IReplierInfo {
  enabled: boolean;
  assets: IReplierAsset[];
}

export interface IReplyProps {
  replyingCommentId: string;
  comment?: IComment;
  replyUser: IReplyUser;
  isPrivateReply?: boolean;
  maxMessageLength?: number;
  suggestedReplyMessage?: string;
  isReplyBtnLoading?: boolean;
  disabledMentions?: boolean;
  replierInfo?: IReplierInfo;
  canSendDMMedia?: boolean;
  canSendDMMediaTooltip?: string;
  onClickReply: IInternalReplyFn;
  replyCapability?: IReplyCapability;
}

export interface IReplierName {
  platformId: string;
  replierInfo?: IReplierInfo;
  replyingAsset?: IObjectOption;
  setReplyingAsset: (asset?: IObjectOption) => void;
}

export interface IReplyBottomActions {
  comment?: IComment;
  isPrivateReply?: boolean;
  replyMessageLength: number;
  maxReplyMessageLength: number;
  isReplyBtnLoading?: boolean;
  isReplyBtnDisabled?: boolean;
  onInternalReplyClick: (type: SendButtonType, value: string[]) => void;
  replyCapability?: IReplyCapability;
}

export interface IReplyInputContainerHeaderProps {
  savedReplyModalOpen: boolean;
  replyUser: IReplyUser;
  repliesSelectorData: IReplyData | undefined;
  isTiktokDM: boolean | undefined;
  canSendDMMedia: boolean | undefined;
  canSendDMMediaTooltip: string | undefined;
  aIPrompts: IAIPromptsResponse;
  aIPromptsForm: React.FC<IAIPromptFormProps>;
  fetchAIPrompts: (query?: string) => void;
  deleteAIPrompt: (id: string) => void;
  setDefaultAIPrompt: (id: string) => void;
  getAIPromptSuggestion: (promptId?: string) => void;
  onGenerateDefaultPromptClick: () => void;
  fetchingAIPromptSuggestion: boolean;
  onUploadReplyImage: (selectedImage: File) => void;
  onSelectSharePost: (post: IPost) => void;
  setReplyImageSizeError: React.Dispatch<React.SetStateAction<boolean>>;
  onEmojiSelect: (emoji: EmojiData) => void;
  onSavedReplyClick: () => void;
}
