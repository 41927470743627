import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { BarChart } from "@bbdevcrew/bb_ui_kit_fe";

import { IPostChartProps } from "./Post.type";

export const PostChart: FC<IPostChartProps> = ({ stats, bars, total }) => {
  const { t } = useTranslation();

  return (
    <div>
      {stats && bars ? (
        <BarChart
          yAxis
          xAxis
          tooltip
          width={240}
          height={50}
          barSize={15}
          layout="vertical"
          bars={bars || []}
          data={stats || []}
          xAxisProps={{ type: "number", hide: true }}
          yAxisProps={{ dataKey: "name", type: "category", hide: true }}
          tooltipProps={{ cursor: { fill: "transparent" } }}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
          total={
            total
              ? {
                  name: total.name || t("components:tags:values:total"),
                  color: total.color || "#000",
                }
              : undefined
          }
        />
      ) : null}
    </div>
  );
};
