import { useTranslation } from "react-i18next";
import React from "react";

import SentimentPicker, {
  SentimentValue,
} from "../../../../_common/SentimentPicker/SentimentPicker";
import { TagWorkflowAction } from "./tagWorkflowAction";

import s from "./../WorkflowForm.module.less";

import { IFormComponent } from "../../types";
import { WorkflowItemCategoryType } from "@store/workflows/types";

import WorkflowCategoryIcon from "../../categoryIcon/WorkflowCategoryIcon";

const Action: React.FC<{ type: WorkflowItemCategoryType }> = ({ type, children }) => {
  const { t } = useTranslation();
  return (
    <div className={s.bbWorkflowItemFormAction}>
      <WorkflowCategoryIcon type={type} />
      <label className={s.bbWorkflowItemFormActionLabel}>
        {t(`pages:workflows:form:actions:${type}:label`)}
      </label>
      {children}
    </div>
  );
};

export const SentimentAction: React.FC<IFormComponent<SentimentValue>> = ({ value, onChange }) => (
  <Action type="sentiment">
    <div className={s.bbWorkflowItemFormActionSentimentSelect}>
      <SentimentPicker value={value} onChange={onChange} />
    </div>
  </Action>
);

export const TagAction: React.FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  return (
    <Action type="tag">
      <TagWorkflowAction value={value} onChange={onChange} />
    </Action>
  );
};

export const ArchiveAction: React.FC = () => <Action type="archive" />;

export const HideAction: React.FC = () => <Action type="hide" />;
