import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { CustomTagWorkflowAction } from "./CustomTagWorkflowAction";

import { getCommentTagsAction } from "@store/commentTags/actions";
import { commentTagsSelector } from "@store/commentTags/selectors";

import s from "./TagWorkflowAction.module.less";

import { useSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { IFormComponent } from "@components/care/workflows/types";
import { getTagActionTypeOptions, tagToOption } from "./TagWorkflowAction.helpers";

export const BBTagWorkflowAction: FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [tagType, setTagType] = useState(
    value?.[0]?.startsWith("managed_tag") ? "bb_tag" : "custom_tag",
  );

  const dispatch = useDispatch();

  const clientTags = useSelector(commentTagsSelector).filter(
    tag => tag.key !== "custom_tags" && tag.key !== "ai_tags",
  );

  const getClientTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  const optionsFormatted = useMemo(() => {
    return clientTags.map(tagToOption);
  }, [clientTags]);

  useEffect(() => {
    getClientTags();
    // eslint-disable-next-line
  }, []);

  const onTypeChange = (type: string) => {
    setTagType(type);
    onChange?.([]);
  };

  const { options, SearchInput, searchTerm, setSearchTerm } = useSearch(
    optionsFormatted,
    t("generic:search"),
  );

  return (
    <div className={s.bbTagAction}>
      <SelectStyled
        options={getTagActionTypeOptions()}
        onChange={onTypeChange}
        value={tagType}
        dropdownMatchSelectWidth={true}
        wrapperClassName={s.bbTagActionTypeSelect}
      />
      <span>{t("generic:as")}</span>
      {tagType === "custom_tag" ? (
        <CustomTagWorkflowAction value={value} onChange={onChange} />
      ) : (
        <SelectStyled
          options={options}
          wrapperClassName={s.bbTagActionTypeSelect}
          onChange={val => val.startsWith("managed_tag") && onChange?.([val])}
          value={value?.[0]}
          highlightTerm={searchTerm}
          dropdownPlacement="topRight"
          onDropdownClose={() => setSearchTerm("")}
          DropdownHeader={
            <div className={s.bbTagsDropdownHeader}>
              <span>{t("pages:workflows:form:triggers:time_frame:bbtags:label")}</span>
              {SearchInput}
            </div>
          }
        />
      )}
    </div>
  );
};
