import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import ReplyPubliclyAction from "./ReplyPubliclyWorkflowAction";
import ReplyPrivatelyAction from "./ReplyPrivatelyWorkflowAction";
import ReplyPrivatelyAndPubliclyWorkflowAction from "./ReplyPrivatelyAndPubliclyWorkflowAction";

import { meSelector } from "@store/me/selectors";

import {
  MIN_MINUTES,
  isPrivateReplyWorkflow,
  isPublicToPrivateReplyWorkflow,
} from "./ReplyWorkflowAction.helpers";
import { IReplyWorkflowActionProps } from "./ReplyWorkflowAction.types";

const ReplyWorkflowAction = ({ form, item }: IReplyWorkflowActionProps) => {
  const messageTypesValue = form.getFieldValue("message_types");
  const platformTypesValue = form.getFieldValue("platform_types");

  const me = useSelector(meSelector);

  useEffect(() => {
    const isBBBackofficeUser = me?.is_bb_backoffice_user;

    if (item?.reply) {
      const defaultFields = {
        action: {
          reply_template_id: item?.reply?.reply_template?.id.toString(),
          private_reply_template_id: item?.reply?.private_reply_template?.id.toString(),
          delay_private_reply: item?.reply?.delay_private_reply,
          reply_only_first_private_message: !!item?.reply?.reply_only_first_private_message,
        },
      };
      const BBUserFields = {
        action: {
          ...defaultFields.action,
          overwrite_workflow_name_listing: !!item?.reply?.overwrite_workflow_name_listing,
          hide_for_external_users: !!item?.reply?.hide_for_external_users,
        },
      };

      form.setFieldsValue(isBBBackofficeUser ? BBUserFields : defaultFields);
    } else {
      const defaultFields = {
        action: {
          reply_template_id: undefined,
          private_reply_template_id: undefined,
          delay_private_reply: MIN_MINUTES,
          reply_only_first_private_message: true,
        },
      };
      const BBUserFields = {
        action: {
          ...defaultFields.action,
          overwrite_workflow_name_listing: true,
          hide_for_external_users: true,
        },
      };

      form.setFieldsValue(isBBBackofficeUser ? BBUserFields : defaultFields);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isPublicToPrivateReplyWorkflow(messageTypesValue, platformTypesValue) ? (
        <ReplyPrivatelyAndPubliclyWorkflowAction form={form} item={item} />
      ) : isPrivateReplyWorkflow(messageTypesValue) ? (
        <ReplyPrivatelyAction form={form} item={item} />
      ) : (
        <ReplyPubliclyAction />
      )}
    </>
  );
};

export default ReplyWorkflowAction;
