import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { Col, Grid, Row } from "antd";
import { InboxMenu } from "@containers/InboxMenu/InboxMenu";
import { CommentsGridContainer } from "@containers/Comments/Comments";
import BatchModeButton from "@containers/BatchMode/BatchModeButton";
import BatchModeSelectAll from "@containers/BatchMode/BatchModeSelectAll";
import QuickAutomationMenu from "../_common/quickAutomation/QuickAutomationMenu";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";
import { AlertDanger, InboxEmptyState, InboxCommentsHeader } from "@bbdevcrew/bb_ui_kit_fe";

import { filterDataSelector } from "@store/filters/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";
import { assignToMeLoadingSelector } from "@store/assign/selectors";
import { shortenedSearchesIdSelector } from "@store/shortenedSearches/selectors";

import { getInboxRepliesAction } from "@store/comments/actions";
import { getInboxStatsAction } from "@store/inboxStats/actions";

import s from "./Inbox.module.less";

import {
  IPostCommentBody,
  ReplyPlatformType,
  IGetInboxRepliesPayload,
} from "@store/comments/types";
import {
  IInboxStats,
  useInboxOptions,
  useErrorInboxOptions,
  getInboxParentTabValue,
  getDefaultReplyPlatformTypeOptions,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useInbox } from "@utils/useInbox";
import { useAppFilters } from "@utils/appFilters";
import { useBatchMode } from "@containers/BatchMode/BatchMode";

import { AlertTriangleFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const defaultInboxTab = "care_highlights";

const Inbox = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const { isBatchModeActive } = useBatchMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateSearchDataAndUrl } = useAppFilters();
  const { options: inboxOptions } = useInboxOptions();

  const queryParamsTab = searchParams.get("tab");
  const openReply = searchParams.get("openReply");

  const [sortValue, setSortValue] = useState<string>("desc");
  const { statTabValue, setStatTabValue, onTabChange, assignToMeHandler } = useInbox(
    defaultInboxTab,
    sortValue,
  );
  const [isTabToggled, setIsTabToggled] = useState(false);
  const [replyPlatformType, setReplyPlatformType] = useState<ReplyPlatformType>(
    getDefaultReplyPlatformTypeOptions(t)[0].value,
  );
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);

  const filters = useSelector(filterDataSelector);
  const inboxStats = useSelector(inboxStatsSelector);
  const shortenedSearchesId = useSelector(shortenedSearchesIdSelector);
  const assignToMeLoading = useSelector(assignToMeLoadingSelector);

  const fetchReplies = useCallback(
    (body: IPostCommentBody | IGetInboxRepliesPayload) => {
      const sortVals = body.sort?.split("|");

      return getInboxRepliesAction({
        ...body,
        sort: sortVals && sortVals[0],
        sort_field: sortVals && sortVals[1],
        list_type: body.isReplyModal ? "all" : statTabValue,
      });
    },
    [statTabValue],
  );

  useEffect(() => {
    setShowErrorDisclaimer(!!inboxStats?.stats.errors);
  }, [inboxStats]);

  useEffect(() => {
    setStatTabValue((queryParamsTab || "care_highlights") as keyof IInboxStats);
  }, [queryParamsTab, setStatTabValue]);

  useEffect(() => {
    if (filters) dispatch(getInboxStatsAction(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    if (shortenedSearchesId && statTabValue) {
      setSearchParams({
        ...(openReply && { openReply }),
        tab: statTabValue,
        id: shortenedSearchesId,
      });
    }
  }, [openReply, shortenedSearchesId, setSearchParams, statTabValue]);

  const onFilterAuthorId = (authorIds: string[]) =>
    updateSearchDataAndUrl({ author_ids: authorIds }, "id");

  const onCloseErrorDisclaimer = () => setShowErrorDisclaimer(false);

  const handleTabChange = (tab: keyof IInboxStats) => {
    setIsTabToggled(prev => !prev);
    onTabChange(tab);
    if (tab === "assigned_to_me") {
      dispatch(getInboxStatsAction(filters || {}));
    }
  };

  return filters ? (
    <div className={s.bbInbox} data-cy="inbox-container">
      <Row gutter={30} className={s.bbInboxContentRow}>
        {screens.lg && (
          <Col
            xs={24}
            xl={6}
            xxl={6}
            className={classNames(s.bbInboxContentCol, {
              [s.bbInboxBatchMode]: isBatchModeActive,
            })}
          >
            <InboxMenu
              value={statTabValue}
              onTabChange={handleTabChange}
              useInboxOptions={useInboxOptions}
            />
            {!!inboxStats?.stats.errors && (
              <InboxMenu
                value={statTabValue}
                onTabChange={handleTabChange}
                useInboxOptions={useErrorInboxOptions}
              />
            )}
            <QuickAutomationMenu />
          </Col>
        )}

        <div className={s.bbInboxContentWrapper}>
          <InboxCommentsHeader
            sortValue={sortValue}
            statTabValue={statTabValue || defaultInboxTab}
            batchModeActions={
              <>
                {isBatchModeActive && <BatchModeSelectAll />}
                <BatchModeButton />
              </>
            }
            isBatchModeActive={isBatchModeActive}
            assignToMeLoading={assignToMeLoading}
            onClickAssignToMe={assignToMeHandler}
            stats={inboxStats?.stats || {}}
            onSortChange={val => setSortValue(val)}
            replyPlatformTypeValue={replyPlatformType}
            statTabParent={getInboxParentTabValue(inboxOptions, statTabValue || defaultInboxTab)}
            onReplyPlatformChange={val => setReplyPlatformType(val as ReplyPlatformType)}
          />

          <div className={s.bbInboxMobileFiltersButtonWrapper}>
            <MobileFiltersButton />
          </div>

          {showErrorDisclaimer && statTabValue === "errors" && (
            <div data-stonly-target={"app-global__inbox-errors-tab--error-disclaimer-box"}>
              <AlertDanger
                size="large"
                align="center"
                className={s.bbErrorDisclaimer}
                onClose={onCloseErrorDisclaimer}
                message={
                  <div className={s.bbErrorDisclaimerMessage}>
                    {t("components:errorsTabDisclaimer1")}
                    <span className={s.bbErrorDisclaimerBox}>
                      <AlertTriangleFilledIcon />
                    </span>
                    {t("components:errorsTabDisclaimer2")}
                  </div>
                }
              />
            </div>
          )}
          <div className={s.bbInboxCommentsGrid}>
            <CommentsGridContainer
              showStarAction
              showHideAction
              showDeleteAction
              showLikeAction
              showDetailsAction
              showLinkedInReactionAction
              showArchiveAction
              withPostPreview
              filters={filters}
              sortValue={sortValue}
              providedFetchAction={fetchReplies}
              key={`comment-grid-${statTabValue}-${isTabToggled}`}
              EmptyState={
                <InboxEmptyState
                  tab={statTabValue || defaultInboxTab}
                  assignToMeLoading={assignToMeLoading}
                  stats={inboxStats?.stats || {}}
                  onAssignClick={assignToMeHandler}
                />
              }
              replyPlatformType={statTabValue === "replied" ? replyPlatformType : undefined}
              providedFilterAuthorIdAction={(authorIds: string[]) => onFilterAuthorId(authorIds)}
            />
          </div>
        </div>
      </Row>
    </div>
  ) : null;
};

export default Inbox;
