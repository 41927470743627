import { ajax } from "rxjs/ajax";
import { Observable, of, retry, takeUntil } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import * as actions from "./actions";

import { RootState } from "../";
import { AccountsActionsType, ICheckOnboadingAIPayload, IGetFieldsResponse } from "./types";
import { accounts, onboarding } from "@utils/paths";
import { DELETE_ACCOUNT } from "./actionTypes";
import { deleteAccountFailure, deleteAccountSuccess } from "./actions";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { AIModalStateEnum } from "@store/aiInsights/types";

const RESPONSE_STATUS_CHECK_INTERVAL = 5_000;
const RESPONSE_STATUS_CHECK_RETRIES = 10;

export const deleteAccount = (
  action$: Observable<AccountsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_ACCOUNT)),
    switchMap(a =>
      ajax({
        method: "DELETE",
        url: accounts,
        body: a.payload,
        headers: getAuthAPIHeaders(state$),
      }).pipe(
        map(e => e.response),
        map(() => deleteAccountSuccess()),
        catchError(err => handleError(err, deleteAccountFailure)),
      ),
    ),
  );

export const checkAIModalAvailable = (
  action$: Observable<AccountsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.checkOnboardingAIAction)),
    switchMap(() => {
      return ajax
        .get<ICheckOnboadingAIPayload>(`${onboarding}/available`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => actions.checkOnboardingAISuccessAction(data)),
          catchError(e => handleError(e, actions.checkOnboardingAIFailureAction)),
        );
    }),
  );

export const getAIOnboardingFields = (
  action$: Observable<AccountsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getOnboardingFieldsAction)),
    switchMap(a => {
      return ajax
        .get<IGetFieldsResponse>(
          `${onboarding}/response/${a.payload.id}`,
          getAuthAPIHeaders(state$),
        )
        .pipe(
          retry({
            count: RESPONSE_STATUS_CHECK_RETRIES,
            delay: RESPONSE_STATUS_CHECK_INTERVAL,
            resetOnSuccess: true,
          }),
          map(e => e.response),
          map(data =>
            actions.getOnboardingFieldsSuccessAction({
              ...data,
              initial_message: a.payload.initial_message,
              isInitialState: a.payload.isInitialState,
              goToNext: a.payload.goToNext,
            }),
          ),
          catchError(e => handleError(e, actions.getOnboardingFieldsFailureAction)),
          takeUntil(
            state$.pipe(filter(state => state.accounts.aiModalState === AIModalStateEnum.Closed)),
          ),
        );
    }),
  );

export const sendAIOnboardingFields = (
  action$: Observable<AccountsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.postOnboardingFieldsAction)),
    switchMap(a =>
      ajax
        .post<IGetFieldsResponse>(
          `${onboarding}/send/${a.payload.id}`,
          {
            fields: a.payload.fields,
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            actions.getOnboardingFieldsAction({
              initial_message: data.initial_message,
              id: data.message_id,
              goToNext: a.payload.goToNext,
            }),
          ),

          catchError(() => of(actions.postOnboardingFieldsFailureAction())),
        ),
    ),
  );
