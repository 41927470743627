import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "antd";
import { addToast, ArchiveIcon, Button, Modal, Table } from "@bbdevcrew/bb_ui_kit_fe";
import { Breadcrumbs } from "@components/_common/breadcrumbs/Breadcrumbs";
import AlertsTableRow from "./AlertsTableRow";
import EmptyState from "./EmptyState";
import WorkflowCreationModal from "@components/care/workflows/creationModal/WorkflowCreationModal";

import { fetchAlerts, updateBulkAlerts } from "@store/alerts/actions";
import {
  getAlerts,
  getFetchedAlerts,
  getFetchingAlerts,
  getTotal,
  getUpdatingBulkAlerts,
} from "@store/alerts/selectors";
import { getWorkflowsAction } from "@store/workflows/actions";
import { resetWorkflowsAction } from "@store/workflows/actions";
import { workflowEditedSelector } from "@store/workflows/selectors";

import s from "./AlertsTable.module.less";

import { getTableColumns, TABLE_PAGE_SIZE } from "./AlertsTable.helpers";
import { useCurrentFilters } from "@utils/useCurrentFilters";
import { WORKFLOW_TOASTS_DURATION } from "@components/care/workflows/WorkflowsContent";
import { PAGE_SIZE_OPTIONS } from "../posts/postsTable/helpers";

import { ITableRow } from "@bbdevcrew/bb_ui_kit_fe";
import { IAlert, ISort } from "@store/alerts/types";
import { IWorkflowItem } from "@store/workflows/types";

export const AlertsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useCurrentFilters();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  const [pagination, setPagination] = useState({ size: TABLE_PAGE_SIZE, page: 1 });
  const [sort, setSort] = useState<ISort>({ field: "created_time", order: "desc" });
  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkflowItem>();

  const alerts = useSelector(getAlerts);
  const loading = useSelector(getFetchingAlerts);
  const fetched = useSelector(getFetchedAlerts);
  const total = useSelector(getTotal);
  const updatingBulkAlerts = useSelector(getUpdatingBulkAlerts);
  const workflowEdited = useSelector(workflowEditedSelector);

  const archiveAllAlerts = useCallback(() => {
    dispatch(
      updateBulkAlerts(
        alerts.map(alert => alert.id),
        "archived",
      ),
    );
  }, [dispatch, alerts]);

  useEffect(() => {
    if (filters) {
      const {
        data_range_option,
        start_time,
        end_time,
        asset_ids,
        platform_types,
        post_types,
        post_ids,
        post_tags,
      } = filters;

      dispatch(
        fetchAlerts({
          filters: {
            data_range_option,
            start_time,
            end_time,
            asset_ids,
            platform_types,
            post_types,
            post_ids,
            post_tags,
          },
          pagination,
          sort,
        }),
      );
    }
  }, [pagination, sort, filters, dispatch]);

  useEffect(() => {
    dispatch(getWorkflowsAction());

    return () => {
      dispatch(resetWorkflowsAction());
    };
  }, [dispatch, workflowEdited]);

  useEffect(() => {
    if (workflowEdited) {
      setWorkflowModalOpen(false);
      setSelectedWorkflow(undefined);
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:editSuccess"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowEdited, t]);

  const onEditWorkflow = useCallback((workflow: IWorkflowItem) => {
    setSelectedWorkflow(workflow);
    setWorkflowModalOpen(true);
  }, []);

  const TableRow = useCallback(
    (props: ITableRow<IAlert>) => {
      return <AlertsTableRow {...props} index={props.index} onEdit={onEditWorkflow} />;
    },
    [onEditWorkflow],
  );

  return (
    <>
      <div className={s.bbAlertsTable}>
        <div className={s.bbAlertsTableHeader}>
          <Breadcrumbs title={t("components:listen:alerts:title")} />
          {!isMobile && (
            <Button
              _size="sm"
              _type="on-surface"
              onClick={archiveAllAlerts}
              className={s.bbArchiveButton}
              loading={updatingBulkAlerts}
            >
              <ArchiveIcon />
              {t("generic:archiveAll")}
            </Button>
          )}
        </div>

        {fetched && alerts.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <p className={s.bbAlertsTableDescription}>
              {t("components:listen:alerts:description")}
            </p>
            <Table<IAlert>
              data={alerts}
              onSort={nextSort => {
                setSort({
                  field: nextSort.id_name,
                  order: nextSort.sort || "desc",
                });
              }}
              loading={loading}
              tableRow={TableRow}
              columns={getTableColumns(isMobile)}
              pagination={{
                total: total,
                pageSize: pagination.size,
                currentPage: pagination.page,
                onChangePage: (page, pageSize) => {
                  setPagination({
                    size: pageSize || TABLE_PAGE_SIZE,
                    page,
                  });
                },
                showSizeChanger: true,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                pageSizeTitle: t("components:sortableTable:pageSizeTitle"),
              }}
              emptyTableText={t("generic:emptyTable")}
              pageOutOfText={[
                t("components:sortableTable:pageOutOfPart1"),
                t("components:sortableTable:pageOutOfPart2"),
              ]}
            />
          </>
        )}
      </div>
      <Modal
        open={workflowModalOpen}
        onCancel={() => {
          setWorkflowModalOpen(false);
          setSelectedWorkflow(undefined);
        }}
        width={800}
        centered
        noPadding
        responsive
        hideFooter
        hideHeader
      >
        <WorkflowCreationModal item={selectedWorkflow} />
      </Modal>
    </>
  );
};
