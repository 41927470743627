import { AIModalStateEnum } from "@store/aiInsights/types";
import { RootState } from "../";

export const fromAccounts = (state: RootState) => state.accounts;

export const deletingAccount = (state: RootState) => fromAccounts(state).deletingAccount;
export const deletedAccount = (state: RootState) => fromAccounts(state).deletedAccount;

export const aiOnboardingAvailable = (state: RootState) =>
  fromAccounts(state).isAIOnboardingAvailable;

export const aiOnboardingModalState = (state: RootState) => fromAccounts(state).aiModalState;

export const aiOnboardingModalOpen = (state: RootState) =>
  fromAccounts(state).aiModalState !== AIModalStateEnum.Closed;

export const aiOnboardingModalExpanded = (state: RootState) =>
  fromAccounts(state).aiModalState === AIModalStateEnum.Expanded;

export const aiOnboardingMessageId = (state: RootState) => fromAccounts(state).id;
export const aiOnboardingFieldsSelector = (state: RootState) => fromAccounts(state).fields;
export const fetchingOnboardingFieldsSelector = (state: RootState) =>
  fromAccounts(state).fetchingFields;
export const fetchedOnboardingFieldsSelector = (state: RootState) =>
  fromAccounts(state).fetchedFields;

export const aiOnboardingMessagesSelector = (state: RootState) => fromAccounts(state).messages;
export const aiOnboardingStepSelector = (state: RootState) => fromAccounts(state).currentStep;
export const isInitialStateSelector = (state: RootState) => fromAccounts(state).isInitialState;
export const aiOnboardingIntialMessageSelector = (state: RootState) =>
  fromAccounts(state).initial_message;
