import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Grid } from "antd";
import { CollapsibleGroup } from "@bbdevcrew/bb_ui_kit_fe";
import InternalNotes from "./InternalNotes";
import { CommentDetails } from "@components/_common/CommentDetail/CommentDetails";
import CommentZendeskTicketDetails from "@components/_common/CommentZendeskTicketDetails/CommentZendeskTicketDetails"; // eslint-disable-line max-len

import { internalNotesSelector } from "@store/internalNotes/selectors";

import s from "./ReplyModal.module.less";

import { IReplyModalRightPanelProps } from "./ReplyModal.type";

import { ActivityIcon } from "@assets/index";
import { CommentIcon, ZendeskIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ReplyModalRightPanel: FC<IReplyModalRightPanelProps> = ({
  replyData,
  filters,
  listId,
  zendeskTicket,
  currentView,
  onClose,
}) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const isMobile = screens.md === false;

  const commentId = replyData.replying_comment_id;

  const [expandedKey, setExpandedKey] = useState<string | undefined>("details");

  const internalNotes = useSelector(internalNotesSelector);

  return (
    <div
      className={classNames(s.bbReplyModalRightColumn, {
        [s.bbReplyModalRightColumnMobile]: isMobile,
      })}
    >
      <CollapsibleGroup
        expandedKey={expandedKey}
        onItemClick={setExpandedKey}
        items={[
          {
            title: t("components:comments:details:title"),
            headerPrefix: <CommentIcon />,
            hidden: currentView !== "details" || !replyData.post_preview?.id,
            activeKey: "details",
            children: (
              <CommentDetails
                filters={filters}
                replyData={replyData}
                listId={listId}
                onClose={onClose}
              />
            ),
          },
          {
            title: (
              <div className={s.bbNotesTitle}>
                <span>{t("components:comments:activity:title")}</span>
                {expandedKey !== "internal_notes" && (
                  <span className={s.bbNotesCount}>
                    {internalNotes.length ? <div>{internalNotes.length}</div> : null}
                  </span>
                )}
              </div>
            ),
            activeKey: "internal_notes",
            headerPrefix: <ActivityIcon />,
            hidden: currentView !== "details",
            className: s.bbNotes,
            children: (
              <InternalNotes
                commentId={commentId}
                collapsed={expandedKey !== "internal_notes"}
                count={internalNotes.length}
              />
            ),
          },
          {
            title: t("components:comments:zendeskTicket:title"),
            headerPrefix: <ZendeskIcon />,
            activeKey: "zendesk",
            hidden: !zendeskTicket,
            className: s.bbZendeskTicket,
            children: zendeskTicket ? <CommentZendeskTicketDetails ticket={zendeskTicket} /> : null,
          },
        ]}
      />
    </div>
  );
};

export default ReplyModalRightPanel;
