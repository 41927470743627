import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  workflowCategoriesSelector,
  workflowCreatingSelector,
  workflowEditingSelector,
  workflowItemsSelector,
} from "@store/workflows/selectors";
import { createWorkflowAction, editWorkflowAction } from "@store/workflows/actions";

import {
  IWorkflowCategory,
  IWorkflowItem,
  WorkflowItemCategoryType,
  IWorkflowAction,
  IWorkflowTrigger,
  IWorkflowItemPayload,
  IWorkflowEmailAlertAction,
  WorkflowGroupsAndCategoriesType,
  IWorkflowReplyAction,
} from "@store/workflows/types";

export const workflowGroupsAndCategories: WorkflowGroupsAndCategoriesType[] = [
  "all",
  "organize",
  "analyze",
  "protect",
  "tag",
  "archive",
  "assign",
  "sentiment",
  "hide",
  "email_alert",
  "zendesk",
  "engage",
  "reply",
];

function getWorkflowsCategoryIdMap(items: IWorkflowCategory[]) {
  return items.reduce((acc: WorkflowsCategoryIdMap, current) => {
    const { id, ids, sub_categories } = current;
    acc[id] = ids;
    acc = {
      ...acc,
      [id]: ids,
      ...(sub_categories ? getWorkflowsCategoryIdMap(sub_categories) : {}),
    };
    return acc;
  }, {});
}

export function useWorkflows() {
  const [searchParams, setSearchParams] = useSearchParams();

  const items = useSelector(workflowItemsSelector);
  const categories = useSelector(workflowCategoriesSelector);

  const categoryIdMap = useMemo(() => getWorkflowsCategoryIdMap(categories), [categories]);
  const category = searchParams.get("category") || "";
  const activeCategory = useMemo(() => {
    return workflowGroupsAndCategories.includes(category as WorkflowGroupsAndCategoriesType)
      ? category
      : "all";
  }, [category]);

  return {
    items,
    categories,
    activeCategory,
    setCategory(id: string) {
      setSearchParams({ category: id });
    },
    activeCategoryIds: categoryIdMap[activeCategory],
    categoryIdMap,
  };
}

function getTriggerActionMap({
  category,
  trigger,
  action,
}: {
  category: WorkflowItemCategoryType;
  trigger?: IWorkflowTrigger;
  action?: IWorkflowAction;
}) {
  let replyAction = undefined;

  if (category === "reply") {
    action = action as IWorkflowReplyAction;

    const privateReplyTemplate = !!action?.private_reply_template_id;
    const publicReplyTemplate = !!action?.reply_template_id;

    replyAction = {
      ...action,
      reply_types:
        privateReplyTemplate && publicReplyTemplate
          ? ["public", "private"]
          : privateReplyTemplate && !publicReplyTemplate
            ? ["private"]
            : ["public"],
      reply_template_id: action?.reply_template_id
        ? parseFloat(action.reply_template_id)
        : undefined,
      private_reply_template_id: action?.private_reply_template_id
        ? parseFloat(action.private_reply_template_id)
        : undefined,
    };
  }

  return {
    sentiment: {
      sentiment: action,
      trigger: {
        filters: {
          keyword_query: trigger?.keyword_query,
          keyword_query_in_translation: false,
        },
      },
    },
    tag: {
      tags: action,
      trigger: {
        filters: {
          ...trigger?.filters,
          keyword_query: trigger?.keyword_query,
          keyword_query_in_translation: false,
          time_frame: trigger?.time_frame,
        },
      },
    },
    archive: {
      trigger: {
        filters: {
          keyword_query: trigger?.keyword_query,
          keyword_query_in_translation: false,
        },
      },
    },
    hide: {
      trigger: {
        filters: {
          keyword_query: trigger?.keyword_query,
          keyword_query_in_translation: false,
        },
      },
    },
    email_alert: {
      trigger,
      email_alert: action,
    },
    assign: {},
    zendesk: {
      trigger,
      zendesk: action,
    },
    reply: {
      trigger,
      reply: replyAction,
    },
  }[category];
}

export const useWorkflowSubmitHandler = () => {
  const dispatch = useDispatch();

  const isCreating = useSelector(workflowCreatingSelector);
  const isEditing = useSelector(workflowEditingSelector);

  const isLoading = isCreating || isEditing;

  const handleSubmit = useCallback(
    (workflow: IWorkflowItem) => {
      const { id, title, description, category, enabled, trigger, action } = workflow;

      if (category === "email_alert") {
        (action as IWorkflowEmailAlertAction).is_post_based = trigger?.filters?.is_post_based;
        delete trigger?.filters?.is_post_based;
      }

      const payload = {
        title,
        description,
        category,
        enabled,
        trigger,
        ...getTriggerActionMap({ category, trigger, action }),
      } as IWorkflowItemPayload;

      if (id) {
        dispatch(
          editWorkflowAction({
            id,
            ...payload,
          }),
        );
      } else {
        dispatch(createWorkflowAction(payload));
      }
    },
    [dispatch],
  );

  return {
    isLoading,
    handleSubmit,
  };
};

type WorkflowsCategoryIdMap = {
  [key: string]: string[];
};

export const defaultValues: IWorkflowItem = {
  id: "",
  category: "sentiment",
  is_editable: true,
  title: "",
  description: "",
  example: "",
  labels: [],
  enabled: true,
  show_enable_checkbox: true,
  trigger: undefined,
  action: undefined,
};

export const getWorkflowItemTitle = (category: WorkflowItemCategoryType) => {
  return category?.replace(/_/g, " ").toUpperCase();
};
