import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button, SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import {
  workflowsSavedRepliesFetchingSelector,
  workflowsSavedRepliesSelector,
} from "@store/workflows/selectors";
import { getWorkflowsSavedRepliesAction } from "@store/workflows/actions";

import s from "./SavedReplySelect.module.less";

import { ISimpleSelectOptionProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IWorkflowSavedReplyPayload } from "@store/workflows/types";
import { ISavedReplySelectProps } from "./ReplyWorkflowAction.types";

import { ExportIcon } from "@bbdevcrew/bb_ui_kit_fe";
import IllustrationSearch from "@assets/IllustrationSearch.svg";

export const SavedReplySelect: React.FC<ISavedReplySelectProps> = ({
  value,
  onChange,
  withFooter,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectRef = useRef<Partial<{ close: () => void }>>(null);

  const [query, setQuery] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState<ISimpleSelectOptionProps[]>([]);

  const workflowsSavedReplies = useSelector(workflowsSavedRepliesSelector);
  const workflowsSavedRepliesFetching = useSelector(workflowsSavedRepliesFetchingSelector);

  const getWorkflowsSavedReplies = useCallback(
    (payload: IWorkflowSavedReplyPayload) => dispatch(getWorkflowsSavedRepliesAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    getWorkflowsSavedReplies({
      query: query,
    });
  }, [query, getWorkflowsSavedReplies]);

  useEffect(() => {
    setDropdownOptions(
      workflowsSavedReplies.map(item => ({
        value: item.id || "",
        label: item.name,
        tags: [
          ...(item.tags || []).map(tag => tag.label),
          ...(item.custom_tags || []).map(tag => tag.label),
        ],
      })),
    );
    // eslint-disable-next-line
  }, [workflowsSavedReplies]);

  return (
    <SimpleSelectSingle
      ref={selectRef}
      value={value}
      onChange={onChange}
      options={dropdownOptions}
      allowSearch
      minWidth={672}
      showResultsBeforeSearch
      optionsLoading={workflowsSavedRepliesFetching}
      searchInputPlaceholder={t("generic:search")}
      placeholder={t("pages:workflows:form:actions:reply:replyPubliclyTab:selectPlaceholder")}
      onSearch={newQuery => setQuery(String(newQuery))}
      className={s.bbSavedReplySelect}
      footer={
        withFooter && (
          <div className={s.bbSavedReplySelectFooter}>
            <div className={s.bbSavedReplySelectFooterLeft}>
              <IllustrationSearch />
            </div>
            <div className={s.bbSavedReplySelectFooterRight}>
              <div className={s.bbSavedReplySelectFooterTitle}>
                {t("pages:workflows:form:actions:reply:replyPubliclyTab:empty:title")}
              </div>
              <div className={s.bbSavedReplySelectFooterDescription}>
                {t("pages:workflows:form:actions:reply:replyPubliclyTab:empty:description")}
              </div>
              <Button
                _size="sm"
                _type="primary"
                onClick={() => {
                  selectRef.current?.close?.();
                  window.open("/settings/saved-replies", "_blank");
                }}
                className={s.bbSavedReplySelectFooterButton}
              >
                {t("pages:workflows:form:actions:reply:replyPubliclyTab:empty:action")}
                <ExportIcon />
              </Button>
            </div>
          </div>
        )
      }
    />
  );
};
