import { createAction } from "typesafe-actions";
import {
  CHECK_AI_AVAILABLE,
  CHECK_AI_AVAILABLE_FAILURE,
  CHECK_AI_AVAILABLE_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_SUCCESS,
  GET_ONBOARDING_FIELDS,
  GET_ONBOARDING_FIELDS_FAILURE,
  GET_ONBOARDING_FIELDS_SUCCESS,
  POST_ONBOARDING_FIELDS,
  POST_ONBOARDING_FIELDS_FAILURE,
  SET_AI_MODAL_STATE,
} from "./actionTypes";
import {
  ICheckOnboadingAIPayload,
  IDeleteAccountPayload,
  IGetFields,
  IGetFieldsResponse,
  ISendFieldsPayload,
} from "./types";
import { AIModalStateEnum } from "@store/aiInsights/types";

export const deleteAccount = createAction(DELETE_ACCOUNT)<IDeleteAccountPayload>();
export const deleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS)();
export const deleteAccountFailure = createAction(DELETE_ACCOUNT_FAILURE)();

export const checkOnboardingAIAction = createAction(CHECK_AI_AVAILABLE)();
export const checkOnboardingAISuccessAction = createAction(
  CHECK_AI_AVAILABLE_SUCCESS,
)<ICheckOnboadingAIPayload>();
export const checkOnboardingAIFailureAction = createAction(CHECK_AI_AVAILABLE_FAILURE)();

export const toggleAIOnboardingAction = createAction(SET_AI_MODAL_STATE)<AIModalStateEnum>();

export const getOnboardingFieldsAction = createAction(GET_ONBOARDING_FIELDS)<IGetFields>();
export const getOnboardingFieldsSuccessAction = createAction(
  GET_ONBOARDING_FIELDS_SUCCESS,
)<IGetFieldsResponse>();
export const getOnboardingFieldsFailureAction = createAction(GET_ONBOARDING_FIELDS_FAILURE)();

export const postOnboardingFieldsAction =
  createAction(POST_ONBOARDING_FIELDS)<ISendFieldsPayload>();
export const postOnboardingFieldsFailureAction = createAction(POST_ONBOARDING_FIELDS_FAILURE)();
