import cn from "classnames/bind";
import React, { FC } from "react";

import { IUser } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SubscriberOption.module.less";

interface ISubscriberOptionProps {
  user: IUser;
}

const cx = cn.bind(s);

export const SubscriberOption: FC<ISubscriberOptionProps> = ({ user: { display_name, email } }) => {
  return (
    <div className={s.bbSubscriberOption} data-cy="subscriber-option">
      <span
        className={cx(s.bbSubscriberOptionLabel, {
          [s.bbSubscriberOptionLabelBold]: display_name === "Me",
        })}
      >
        {display_name}
      </span>
      <small className={s.bbSubscriberOptionEmail}>{email}</small>
    </div>
  );
};
