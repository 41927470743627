import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Menu } from "antd";
import { Button, Dropdown, DeleteIcon, SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { IWorkflowActionsProps } from "./helpers";

import s from "./WorkflowItem.module.less";

import { MoreActionsIcon, EditIcon } from "@bbdevcrew/bb_ui_kit_fe";

const WorkflowActions: FC<IWorkflowActionsProps> = ({
  onEditClick,
  onDeleteClick,
  onExploreClick,
  isActionsDropdownOpen,
  setIsActionsDropdownOpen,
  category,
}) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      {category === "email_alert" && (
        <Menu.Item
          key="explore"
          icon={<SearchIcon />}
          onClick={onExploreClick}
          className={s.bbActionItem}
          data-cy="workflow-item-action-explore"
        >
          {t("pages:workflows:exploreAction")}
        </Menu.Item>
      )}
      <Menu.Item
        key="edit"
        icon={<EditIcon />}
        onClick={onEditClick}
        className={s.bbActionItem}
        data-cy="workflow-item-action-edit"
      >
        {t("generic:edit")}
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteIcon />}
        onClick={onDeleteClick}
        className={s.bbActionItem}
        data-cy="workflow-item-action-delete"
      >
        {t("generic:delete")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={s.bbActionsDropdown}>
      <Dropdown
        triggerMode="click"
        placement="bottomRight"
        open={isActionsDropdownOpen}
        setOpen={(open, event) => {
          event?.stopPropagation();
          setIsActionsDropdownOpen(open);
        }}
        menuClassName={s.bbActionsDropdownMenu}
        trigger={
          <span>
            <Button _size="md" _type="link" className={s.bbActionsDropdownTrigger}>
              <MoreActionsIcon />
            </Button>
          </span>
        }
      >
        {menu}
      </Dropdown>
    </div>
  );
};

export default WorkflowActions;
