import React, { FC } from "react";

import { IWorkflowItemCardProps } from "../helpers";

import s from "./WorkflowItemCard.module.less";

import WorkflowArrowDown from "@assets/WorkflowArrowDown.svg";

const WorkflowItemCard: FC<IWorkflowItemCardProps> = ({
  title,
  icon: Icon,
  pointArrow = false,
  children,
}) => (
  <div className={s.WorkflowItemCard}>
    <h2 className={s.WorkflowItemCardTitle}>
      <span className={s.WorkflowItemCardTitleText}>
        {Icon && <Icon />} {title}
      </span>
    </h2>
    <div className={s.WorkflowItemCardContent}>{children}</div>
    {pointArrow && (
      <div className={s.WorkflowItemCardArrow}>
        <WorkflowArrowDown />
      </div>
    )}
  </div>
);

export default WorkflowItemCard;
