import { IWorkflowCategory } from "@store/workflows/types";

import {
  EyeOffIcon,
  ISideNavItem,
  TagIcon,
  ArchiveIcon,
  ReplyIcon,
  UserPlusIcon,
  GridIcon,
  ChartIcon,
  PositiveIcon,
  SafetyIcon,
  AlertTriangleIcon,
  ZendeskIcon,
  CommentIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ArrowCornerRightDownIcon } from "@assets/index";

export type WorkflowCategoryIconMap = {
  [key: string]: string;
};

export const WorkflowCategoryIconMap: WorkflowCategoryIconMap = {
  all: GridIcon,
  analyze: ChartIcon,
  tag: TagIcon,
  sentiment: PositiveIcon,
  organize: ArrowCornerRightDownIcon,
  archive: ArchiveIcon,
  assign: UserPlusIcon,
  protect: SafetyIcon,
  hide: EyeOffIcon,
  email_alert: AlertTriangleIcon,
  zendesk: ZendeskIcon,
  engage: CommentIcon,
  reply: ReplyIcon,
};

export const mapWorkflowCategoriesToSideNav = (
  categories: IWorkflowCategory[],
  categoryItemCount: (ids: string[]) => number,
  activeCategory: string,
  setCategory: (id: string) => void,
): ISideNavItem[] =>
  categories.map(({ id, label, ids, sub_categories }) => ({
    key: id,
    label,
    icon: WorkflowCategoryIconMap[id],
    active: id === activeCategory,
    badge: categoryItemCount(ids),
    showBadge: true,
    onClick: () => setCategory(id),
    children: sub_categories
      ? mapWorkflowCategoriesToSideNav(
          sub_categories,
          categoryItemCount,
          activeCategory,
          setCategory,
        )
      : [],
    "data-cy": "workflows-menu-item",
  }));
