import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import { SavedReplySelect } from "./SavedReplySelect";
import { FormErrorMessage, Tooltip, Icon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyWorkflowAction.module.less";

import { getActionFormItemName } from "./ReplyWorkflowAction.helpers";
import { IPublicReplyWorkflowActionProps } from "./ReplyWorkflowAction.types";

import { ReplyIcon, HelpIcon, InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ReplyPubliclyAction: FC<IPublicReplyWorkflowActionProps> = ({ hideTitle }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbReplyWorkflowAction}>
      {!hideTitle && (
        <div className={s.bbReplyWorkflowActionTitle}>
          <div className={s.bbReplyWorkflowActionTitleIcon}>
            <ReplyIcon />
          </div>
          <div className={s.bbReplyWorkflowActionTitleText}>
            {t("pages:workflows:form:actions:reply:title")}
          </div>
        </div>
      )}

      <div className={s.bbReplyPubliclySelectLabel}>
        {t("pages:workflows:form:actions:reply:replyPubliclyTab:selectTitle")}
      </div>
      <Form.Item
        name={getActionFormItemName("reply_template_id")}
        rules={[
          {
            required: true,
            message: (
              <FormErrorMessage>
                {t("pages:workflows:form:actions:reply:replyPubliclyTab:selectError")}
              </FormErrorMessage>
            ),
          },
        ]}
      >
        <SavedReplySelect withFooter />
      </Form.Item>
      <div className={s.bbReplyPubliclyDisclaimer}>
        <InformationIcon />
        {t("pages:workflows:form:actions:reply:replyPubliclyTab:disclaimer")}
      </div>
      <div className={s.bbReplyPubliclyLimits}>
        {t("pages:workflows:form:actions:reply:replyPubliclyTab:limits:title")}
        <ul>
          <li className={s.bbReplyPubliclyLimit}>
            {t("pages:workflows:form:actions:reply:replyPubliclyTab:limits:limit1")}
          </li>
          <li className={s.bbReplyPubliclyLimit}>
            <div className={s.bbReplyPubliclyLimitWithTooltip}>
              {t("pages:workflows:form:actions:reply:replyPubliclyTab:limits:limit2")}
              <Tooltip
                overlayStyle={{ zIndex: 100001 }}
                title={t("pages:workflows:form:actions:reply:intervalTooltip", {
                  count: 6,
                })}
              >
                <Icon icon={HelpIcon} _size="sm" />
              </Tooltip>
            </div>
          </li>
          <li className={s.bbReplyPubliclyLimit}>
            {t("pages:workflows:form:actions:reply:replyPubliclyTab:limits:limit3")}
          </li>
          <li className={s.bbReplyPubliclyLimit}>
            {t("pages:workflows:form:actions:reply:replyPubliclyTab:limits:limit4")}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReplyPubliclyAction;
