import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Form, FormInstance } from "antd";
import ReplyAction from "./actions/replyWorkflowAction";
import { ReplyTrigger } from "./triggers/ReplyTrigger";
import { KeywordTrigger } from "./triggers/KeywordTrigger";
import WorkflowItemCard from "../item/card/WorkflowItemCard";
import { TagWorkflowTrigger } from "./triggers/tagWorkflowTrigger";
import { FormErrorMessage, InputWhiteBordered } from "@bbdevcrew/bb_ui_kit_fe";
import { ArchiveAction, SentimentAction, TagAction, HideAction } from "./actions";
import { EmailAlertOrZendeskWorkflowTrigger } from "./triggers/emailAlertOrZendeskWorkflowTrigger";
import { EmailAlertWorkflowAction } from "./actions/emailAlertWorkflowAction";
import { EmailAlertWorkflowThreshold } from "./actions/emailAlertWorkflowAction/emailAlertWorkflowThreshold"; // eslint-disable-line max-len
import ZendeskWorkflowAction from "./actions/zendeskWorkflowAction";

import { setWorkflowFormDirty } from "@store/workflows/actions";

import s from "./WorkflowForm.module.less";

import { getInitialValues } from "./WorkflowForm.helpers";
import { IWorkflowItem, WorkflowItemCategoryType } from "@store/workflows/types";

import { PlayOutlineIcon } from "@assets/index";
import { NotificationsIcon, ChartIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IWorkflowFormProps {
  item: IWorkflowItem;
  form: FormInstance<IWorkflowItem>;
  workflowType: WorkflowItemCategoryType;
  enabled: boolean;
  onFinish?: (values: IWorkflowItem) => void;
}

const WorkflowForm: FC<IWorkflowFormProps> = ({ item, form, enabled, workflowType, onFinish }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialEnabledValue = useRef<boolean>(enabled);

  const [formErrors, setFormErrors] = useState<string[]>([]);

  useEffect(() => {
    form.setFieldsValue({ enabled });
    dispatch(setWorkflowFormDirty(enabled !== initialEnabledValue.current));
  }, [form, enabled, dispatch]);

  useEffect(
    () => () => {
      dispatch(setWorkflowFormDirty(false));
    },
    [dispatch],
  );

  const workflowTriggerActionMapping = {
    sentiment: {
      trigger: (
        <Form.Item
          name={["trigger", "keyword_query"]}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:triggers:keywords:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <KeywordTrigger />
        </Form.Item>
      ),
      action: (
        <Form.Item
          name="action"
          rules={[
            {
              required: true,
              validateTrigger: "onBlur",
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:actions:sentiment:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <SentimentAction />
        </Form.Item>
      ),
    },
    tag: {
      trigger: (
        <Form.Item name="trigger" className={s.bbWorkflowItemTrigger}>
          <TagWorkflowTrigger form={form} />
        </Form.Item>
      ),
      action: (
        <Form.Item
          name="action"
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:actions:tag:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <TagAction />
        </Form.Item>
      ),
    },
    archive: {
      trigger: (
        <Form.Item
          name={["trigger", "keyword_query"]}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:triggers:keywords:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <KeywordTrigger />
        </Form.Item>
      ),
      action: <ArchiveAction />,
    },
    email_alert: {
      trigger: (
        <Form.Item name={["trigger", "filters"]} className={s.bbWorkflowItemTrigger}>
          <EmailAlertOrZendeskWorkflowTrigger form={form} />
        </Form.Item>
      ),
      threshold: (
        <Form.Item name="action" className={s.bbWorkflowItemTrigger}>
          <EmailAlertWorkflowThreshold form={form} />
        </Form.Item>
      ),
      action: (
        <Form.Item name="action" className={s.bbWorkflowItemTrigger}>
          <EmailAlertWorkflowAction form={form} />
        </Form.Item>
      ),
    },
    assign: {
      trigger: null,
      action: null,
    },
    hide: {
      trigger: (
        <Form.Item
          name={["trigger", "keyword_query"]}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:triggers:keywords:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <KeywordTrigger />
        </Form.Item>
      ),
      action: <HideAction />,
    },
    zendesk: {
      trigger: (
        <Form.Item name={["trigger", "filters"]} className={s.bbWorkflowItemTrigger}>
          <EmailAlertOrZendeskWorkflowTrigger
            title={t("pages:workflows:form:triggers:zendesk:filterMsg")}
            form={form}
          />
        </Form.Item>
      ),
      action: <ZendeskWorkflowAction form={form} />,
    },
    reply: {
      trigger: <ReplyTrigger form={form} errors={formErrors} item={item} />,
      action: <ReplyAction form={form} item={item} />,
    },
  };

  const {
    trigger,
    action,
    threshold,
  }: {
    trigger: JSX.Element | null;
    action: JSX.Element | null;
    threshold?: JSX.Element | null;
  } = workflowTriggerActionMapping[workflowType];

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      initialValues={getInitialValues(item)}
      onFinish={onFinish}
      className={s.bbWorkflowItemFormWrapper}
      onFieldsChange={(_, fields) => {
        const isFormDirty = fields.some(field => field.touched && field.errors?.length === 0);
        dispatch(setWorkflowFormDirty(isFormDirty));
        setFormErrors(
          fields.filter(field => field.errors?.length).map(field => field.name.join(".")),
        );
      }}
    >
      <div className={s.bbWorkflowItemFormItems}>
        <Form.Item name="id" className={s.bbWorkflowItemFormHidden}>
          <input type="hidden" name="id" />
        </Form.Item>
        <Form.Item name="enabled" className={s.bbWorkflowItemFormHidden}>
          <input type="hidden" name="enabled" />
        </Form.Item>
        <Form.Item name="category" className={s.bbWorkflowItemFormHidden}>
          <input type="hidden" name="category" />
        </Form.Item>
        <Form.Item
          name="title"
          label={t("pages:workflows:form:title:label")}
          rules={[
            {
              required: true,
              validateTrigger: "onBlur",
              message: (
                <FormErrorMessage>{t("pages:workflows:form:title:required")}</FormErrorMessage>
              ),
            },
            {
              max: 60,
              validateTrigger: "onBlur",
              message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
            },
            {
              validator: (_, value) => {
                if (value && !value.trim()) {
                  return Promise.reject(
                    <FormErrorMessage>{t("pages:workflows:form:title:required")}</FormErrorMessage>,
                  );
                }
                return Promise.resolve();
              },
              validateTrigger: "onBlur",
            },
          ]}
        >
          <InputWhiteBordered data-cy="workflow-form-title" maxLength={60} />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("pages:workflows:form:description:label")}
          rules={[
            {
              max: 300,
              message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
            },
          ]}
        >
          <InputWhiteBordered maxLength={300} />
        </Form.Item>
      </div>
      <WorkflowItemCard title={t("generic:trigger")} icon={NotificationsIcon} pointArrow>
        {trigger}
      </WorkflowItemCard>
      {threshold && (
        <WorkflowItemCard title={t("generic:threshold")} icon={ChartIcon} pointArrow>
          {threshold}
        </WorkflowItemCard>
      )}
      <WorkflowItemCard title={t("generic:action")} icon={PlayOutlineIcon}>
        {action}
      </WorkflowItemCard>
    </Form>
  );
};

export default WorkflowForm;
