import React from "react";
import classNames from "classnames/bind";
import { position as getCaretPosition, getOffset as getCaretOffset } from "caret-pos";
import { t } from "i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./InternalNotes.module.less";

import { IMention } from "./InternalNotes.types";
import { IInternalNoteMention } from "@store/internalNotes/types";

export const DEFAULT_MAX_LENGTH = 500;
export const NOTES_MAX_COUNT = 50;
const CARET_OFFSET = 30;
const DROPDOWN_WIDTH = 320;
const DROPDOWN_PADDING = 10;
const DROPDOWN_BOTTOM_OFFSET = 100;
const DROPDOWN_LEFT_OFFSET = 12;

export const getDropdownPosition = (textarea: HTMLTextAreaElement) => {
  const caretPosition = getCaretPosition(textarea);
  const caretOffset = getCaretOffset(textarea);

  let dropdownTop =
    caretOffset.top + caretPosition.top - textarea.scrollTop + window.scrollY + CARET_OFFSET;
  let dropdownLeft = caretOffset.left + DROPDOWN_LEFT_OFFSET + window.scrollX;

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (dropdownLeft + DROPDOWN_WIDTH > viewportWidth) {
    dropdownLeft = viewportWidth - DROPDOWN_WIDTH - DROPDOWN_PADDING;
  }

  if (dropdownTop > viewportHeight - DROPDOWN_BOTTOM_OFFSET) {
    dropdownTop = viewportHeight - DROPDOWN_BOTTOM_OFFSET;
  }

  return { top: dropdownTop, left: dropdownLeft };
};

export const mentionHandler = (
  inputValue: string,
  mentionQuery: string,
  mention: string,
  textarea: HTMLTextAreaElement,
) => {
  const cursorPosition = getCaretPosition(textarea).pos;

  const textBeforeMention = inputValue.slice(0, cursorPosition - mentionQuery.length - 1);
  const textAfterCursor = inputValue.slice(cursorPosition);

  const newValue = `${textBeforeMention}@${mention} ${textAfterCursor}`;
  const caretPosition = textBeforeMention.length + mention.length + 2;

  return {
    newValue,
    caretPosition,
    start: textBeforeMention.length,
    end: textBeforeMention.length + mention.length + 1,
  };
};

export const replaceLabelsWithIds = (displayValue: string, mentionMap: IMention[]) => {
  let finalMessage = displayValue;
  let offset = 0; // Track offset due to replacement

  mentionMap.forEach(({ id, label, start, end }) => {
    const mentionText = `@${label}`;
    const adjustedStart = start + offset;
    const adjustedEnd = end + offset;

    if (finalMessage.slice(adjustedStart, adjustedEnd) === mentionText) {
      const replacement = `@${id}`;
      finalMessage =
        finalMessage.slice(0, adjustedStart) + replacement + finalMessage.slice(adjustedEnd);

      // Update offset: new length - old length
      offset += replacement.length - mentionText.length;
    }
  });

  mentionMap.forEach(({ id, label }) => {
    const mentionRegex = new RegExp(`@${label.replace(/\s+/g, "\\s+")}`, "g");
    finalMessage = finalMessage.replace(mentionRegex, `@${id}`);
  });

  return finalMessage;
};

export const getStyledMessage = (
  message: string,
  mentions: IInternalNoteMention[],
  userId?: string,
) => {
  const regex = /@([\w-]+)/g;
  const parts = [];
  let currentIndex = 0;

  let match;
  while ((match = regex.exec(message)) !== null) {
    const mentionId = match[1];
    const mention = mentions.find(m => m.id === mentionId);

    if (mention) {
      if (match.index > currentIndex) {
        parts.push(message.slice(currentIndex, match.index));
      }

      const isCurrentUser = mention.id === userId;
      parts.push(
        <span
          key={mention.id}
          className={classNames(s.bbNotesUserMention, {
            [s.bbNotesCurrentUserMention]: isCurrentUser,
            [s.bbNotesUserMentionDisabled]: !!mention.is_disabled,
          })}
        >
          <Tooltip
            title={
              !!mention.is_disabled
                ? t("components:comments:activity:internalNotes:disabledUserTooltip")
                : undefined
            }
          >
            @{mention.pretty}
          </Tooltip>
        </span>,
      );

      currentIndex = match.index + match[0].length;
    }
  }

  if (currentIndex < message.length) {
    parts.push(message.slice(currentIndex));
  }

  return <div>{parts}</div>;
};

export const calculateLengthWithMentions = (value: string, mentionMap: IMention[]) => {
  let effectiveLength = value.length;

  mentionMap.forEach(mention => {
    const mentionLabel = mention.label;
    const mentionRegex = new RegExp(`\\b${mentionLabel}\\b`, "g");
    const matches = value.match(mentionRegex);

    if (matches) {
      matches.forEach(() => {
        effectiveLength += 37 - mentionLabel.length;
      });
    }
  });

  return effectiveLength;
};

export const splitFormattedText = (text: string) => {
  const parseText = (input: string) => {
    const parts = input.split(/({{.*?}}|__.*?__)/g);

    return parts.map((part, index) => {
      if (/^{{.*}}$/.test(part)) {
        return (
          <span key={index} className={s.bbNotesHistoryItemBold}>
            {parseText(part.replace(/{{|}}/g, ""))}
          </span>
        );
      } else if (/^__.*__$/.test(part)) {
        return (
          <span key={index} className={s.bbNotesHistoryItemItalic}>
            {part.replace(/__/g, "")}
          </span>
        );
      }
      return part;
    });
  };

  return <div>{parseText(text)}</div>;
};
