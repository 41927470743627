import { EmailAlertRangeEnum } from "@store/workflows/types";
import { TFunction } from "i18next";
import { weekdays } from "@components/_common/weekdaysSelect/helpers";

export const getRangeOptions = (count: number, t: TFunction) => [
  {
    value: EmailAlertRangeEnum.HOUR,
    label: t("components:notificationsPanel:modals:create:form:threshold:hour", {
      count,
    }),
  },
  {
    value: EmailAlertRangeEnum.DAY,
    label: t("components:notificationsPanel:modals:create:form:threshold:day", {
      count,
    }),
  },
  {
    value: EmailAlertRangeEnum.WEEK,
    label: t("components:notificationsPanel:modals:create:form:threshold:week", {
      count,
    }),
  },
  {
    value: EmailAlertRangeEnum.MONTH,
    label: t("components:notificationsPanel:modals:create:form:threshold:month", {
      count,
    }),
  },
];

export const defaultSelectedDays = weekdays.filter(day => day !== "Saturday" && day !== "Sunday");

export const timeOptions = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
];
