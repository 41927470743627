/* eslint-disable max-len */
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  TabsScrollable,
  TabPane,
  SelectOld,
  HelpIcon,
  AlertInfo,
  Tooltip,
  Divider,
  Input,
} from "@bbdevcrew/bb_ui_kit_fe";
import AmPmSwitch from "@components/_common/amPmSwitch/AmPmSwitch";
import WeekdaysSelect from "@components/_common/weekdaysSelect/WeekdaysSelect";
import { Form } from "antd";

import { meSelector } from "@store/me/selectors";

import s from "./EmailAlertWorkflowThreshold.module.less";

import { EmailAlertRangeEnum, IWorkflowEmailAlertAction } from "@store/workflows/types";
import { defaultSelectedDays, timeOptions } from "./EmailAlertWorkflowThreshold.helpers";
import { AmPmType } from "@store/notifications/types";
import { FormInstance } from "antd";
import { getRangeOptions } from "./EmailAlertWorkflowThreshold.helpers";

interface IEmailAlertWorkflowThresholdProps {
  form: FormInstance;
}

export const EmailAlertWorkflowThreshold: React.FC<IEmailAlertWorkflowThresholdProps> = ({
  form,
}) => {
  const { t } = useTranslation();

  const values = (form.getFieldValue("action") || {}) as IWorkflowEmailAlertAction;
  const defaultSchedule = {
    hour: values.schedule?.hour || 10,
    meridiem: values.schedule?.meridiem || "AM",
    week_days: values.schedule?.week_days || defaultSelectedDays,
  };

  const [alertMessage, setAlertMessage] = useState<string>(
    t(
      `pages:workflows:form:actions:email_alert:notificationFrequency:${
        values?.range_type || EmailAlertRangeEnum.HOUR
      }`,
      { count: values?.range_number || 1 },
    ),
  );

  const me = useSelector(meSelector);

  useEffect(() => {
    if (!values.range_type) {
      form.setFieldsValue({
        action: {
          range_type: EmailAlertRangeEnum.HOUR,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const onTabChange = (key: string) => {
    form.setFieldsValue({
      action: {
        range_type:
          key === EmailAlertRangeEnum.DYNAMIC
            ? EmailAlertRangeEnum.DYNAMIC
            : EmailAlertRangeEnum.DAY,
        is_single_email: values.is_single_email,
        user_ids: values.user_ids,
        threshold: undefined,
        range_number: undefined,
        schedule: defaultSchedule,
      },
    });
  };

  const onMeridiemChange = (amPm: AmPmType) => {
    form.setFieldsValue({
      action: {
        schedule: {
          meridiem: amPm,
        },
      },
    });
  };

  const onRangeTypeChange = (value: string) => {
    if (value !== EmailAlertRangeEnum.DAY && values.schedule) {
      form.resetFields(["schedule"]);
    } else if (value === EmailAlertRangeEnum.DAY) {
      form.setFieldsValue({
        action: {
          schedule: defaultSchedule,
        },
      });
    }

    setAlertMessage(
      t(
        `pages:workflows:form:actions:email_alert:notificationFrequency:${value}${
          value === EmailAlertRangeEnum.DAY ? ":sentence1" : ""
        }`,
        { count: values?.range_number || 1 },
      ),
    );
  };

  const onRangeNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const range = e.target.value;
    const rangeNumber = range !== null ? parseFloat(range.toString()) : range;
    const rangeType: string | undefined = values.range_type;

    form.setFieldsValue({
      action: {
        range_number: rangeNumber,
      },
    });

    if (rangeType) {
      setAlertMessage(
        t(
          `pages:workflows:form:actions:email_alert:notificationFrequency:${rangeType}${
            rangeType === EmailAlertRangeEnum.DAY ? ":sentence1" : ""
          }`,
          { count: rangeNumber || 1 },
        ),
      );
    }
  };

  const onThresholdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const threshold = value !== null ? parseFloat(value.toString()) : value;

    form.setFieldsValue({
      action: {
        threshold,
      },
    });
  };

  return (
    <div className={s.bbEmailAlertThresholdContainer}>
      <div data-cy="notification-config" className={s.bbEmailAlertThresholdConfig}>
        <div className={s.bbEmailAlertThresholdForm}>
          <p className={s.bbEmailAlertThresholdFormLabel}>
            {t("pages:workflows:form:threshold:email_alert:label")}
          </p>
          <TabsScrollable
            centered
            noUnderline
            activeKey={values.range_type === "dynamic" ? "dynamic" : "specific"}
            onChange={onTabChange}
            defaultActiveKey="specific"
            className={s.bbNotifyTabs}
          >
            <TabPane
              tab={t("components:notificationsPanel:modals:create:tabs:threshold:title")}
              tabKey="specific"
            >
              <span className={s.bbScheduleText}>
                {t("components:notificationsPanel:modals:create:form:sentence1")}
              </span>
              <Form.Item
                name={["action", "threshold"]}
                className={s.bbFormItemThreshold}
                data-stonly-target="intelligence-notification__create-notification-modal--comments-number"
                style={{
                  width: `${Math.max((values?.threshold ?? 0).toString().length * 9 + 25, 40)}px`,
                }}
              >
                <Input
                  type="number"
                  min={1}
                  _size="sm"
                  tabIndex={-1}
                  onChange={onThresholdChange}
                  wrapperClassName={s.bbFormInputNumber}
                />
              </Form.Item>
              <span className={s.bbScheduleText}>
                {t("components:notificationsPanel:modals:create:form:sentence2")}
              </span>
              <Form.Item
                name={["action", "range_number"]}
                className={s.bbFormItemRangeNumber}
                data-stonly-target="intelligence-notification__create-notification-modal--time-number"
                style={{
                  width: `${Math.max((values?.range_number ?? 0).toString().length * 9 + 25, 40)}px`,
                }}
              >
                <Input
                  type="number"
                  min={1}
                  _size="sm"
                  tabIndex={-1}
                  onChange={onRangeNumberChange}
                  wrapperClassName={s.bbFormInputNumber}
                />
              </Form.Item>
              <Form.Item
                name={["action", "range_type"]}
                className={s.bbFormRangeType}
                data-stonly-target="intelligence-notification__create-notification-modal--hours-days-months-dropdown"
              >
                <SelectOld
                  tabIndex={-1}
                  bordered={false}
                  onChange={onRangeTypeChange}
                  data-cy="range-type-select"
                  options={getRangeOptions(values?.range_number || 1, t)}
                />
              </Form.Item>
              <br />
              {values.range_type !== EmailAlertRangeEnum.DAY ? (
                <AlertInfo
                  size="medium"
                  message={alertMessage}
                  className={s.bbNotificationFrequencyInfo}
                />
              ) : (
                <>
                  <Divider className={s.bbDivider} />
                  <div className={s.bbNotificationFrequencyTime}>
                    <div className={s.bbNotificationFrequencyTimePhrase}>
                      {t(
                        "pages:workflows:form:actions:email_alert:notificationFrequency:day:sentence1",
                        {
                          count: values?.range_number || 1,
                        },
                      )}{" "}
                      {t("generic:at")}
                      <Form.Item
                        name={["action", "schedule", "hour"]}
                        className={s.bbFormItemNotificationHour}
                        rules={[
                          {
                            required: true,
                            message: " ",
                          },
                        ]}
                      >
                        <SelectOld
                          tabIndex={-1}
                          bordered={false}
                          options={timeOptions}
                          data-cy="notification-hour-select"
                        />
                      </Form.Item>
                      <Form.Item name={["action", "schedule", "meridiem"]} className={s.bbMeridiem}>
                        <AmPmSwitch
                          checked={values.schedule?.meridiem || "AM"}
                          onChange={e => onMeridiemChange(e.target.value as AmPmType)}
                        />
                      </Form.Item>
                      <span className={s.bbTimezone}>
                        <Tooltip
                          overlayInnerStyle={{ width: 365 }}
                          title={t(
                            "components:notificationsPanel:modals:create:form:timezoneDisclaimer",
                            {
                              timezone: me?.timezone || "-",
                              interpolation: { escapeValue: false },
                            },
                          )}
                        >
                          <HelpIcon />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <div className={s.bbNotificationFrequencyDays}>
                    {t(
                      "pages:workflows:form:actions:email_alert:notificationFrequency:day:sentence2",
                    )}

                    <Form.Item name={["action", "schedule", "week_days"]}>
                      <WeekdaysSelect
                        selectedDays={values?.schedule?.week_days || defaultSelectedDays}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <div className={s.bbDynamicTab} data-cy="email-alert-dynamic-threshold">
                  {t("components:notificationsPanel:modals:create:tabs:dynamic:title")}
                  <Tooltip
                    title={t("components:notificationsPanel:modals:create:tabs:dynamic:warning")}
                  >
                    <span className={s.bbDynamicTabWarning}>
                      <HelpIcon />
                    </span>
                  </Tooltip>
                </div>
              }
              tabKey="dynamic"
            />
          </TabsScrollable>
        </div>
      </div>
    </div>
  );
};
