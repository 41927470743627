import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useCallback, useEffect } from "react";

import { Grid } from "antd";
import UserDetails from "./UserDetails";
import { Breadcrumbs } from "./Breadcrumbs";
import { Modal, Loading, debounce, ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentsGridContainer } from "@containers/Comments/Comments";
import { MessageList } from "@components/_common/MessageList/MessageList";
import ReplyModalRightPanel from "./ReplyModalRightPanel";

import {
  repliesSelector,
  postingReplySelector,
  postedReplySelector,
  repliesCursorSelector,
  fetchingRepliesSelector,
  fetchingThreadRepliesSelector,
  fetchedRepliesSelector,
} from "@store/replies/selectors";
import {
  getRepliesAction,
  getRepliesPageAction,
  postCommentReplyAction,
  clearRepliesCommentListAction,
  clearReplyingThreadCommentAction,
  getRepliesAttachmentsAction,
  postCommentReplyApprovalAction,
} from "@store/replies/actions";
import { commentsNavigationIdSelector } from "@store/comments/selectors";

import s from "./ReplyModal.module.less";

import {
  keyCodes,
  useKeyPress,
  isTiktokPlatform,
  isTwitterPlatform,
  PostPlacementEnum,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useReplyModalView } from "./useReplyModalView";
import { useReplyModalContext } from "./ReplyModalContext";
import { useReplyingComment } from "@utils/comments";
import { IReplyAttachment } from "@store/replies/types";
import { CategoryType, IReplyModalContentProps, IReplyModalProps } from "./ReplyModal.type";

const ReplyModalContent: FC<IReplyModalContentProps> = ({
  replyData,
  filters,
  showLikeAction,
  showLinkedInReactionAction,
  showHideAction,
  showDeleteAction,
  showReviewAction,
  showFlagAction,
  showStarAction,
  showArchiveAction,
  showDetailsAction,
  providedFetchAction,
  onClose,
  listId,
}) => {
  const screens = Grid.useBreakpoint();
  const dispatch = useDispatch();

  const isMobile = screens.md === false;

  const {
    author_detail: { categories },
    zendesk_ticket: zendeskTicket,
  } = replyData;

  const { currentView, selectedCategory, setCurrentView, handleChangeCategory } =
    useReplyModalView(categories);
  const comment = useReplyingComment(replyData, listId);

  const commentsNavigationId = useSelector(commentsNavigationIdSelector);
  const isReplyPosting = useSelector(postingReplySelector);
  const repliesFetching = useSelector(fetchingRepliesSelector);
  const repliesCursorValue = useSelector(repliesCursorSelector);

  // eslint-disable-next-line
  const fetchRepliesPage = useCallback(
    debounce(() => {
      if (!repliesFetching && !!repliesCursorValue) {
        dispatch(getRepliesPageAction(repliesCursorValue));
      }
    }, 300),
    [dispatch, repliesFetching, repliesCursorValue],
  );

  const fetchReplies = useCallback(
    commentId => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );

  const postReply = useCallback(
    (
      commentId: string,
      userTag: string,
      message: string,
      rawMessage: string,
      is_ignored: boolean,
      attachment?: IReplyAttachment,
      replyingAssetId?: string,
      isPrivateReply?: boolean,
      userIds?: string[],
    ) => {
      const separator = userTag ? " " : "";
      const body = {
        message: `${userTag}${separator}${message}`,
        raw_message: rawMessage,
        commentId,
        is_ignored,
        replyingAssetId,
        isPrivateReply,
        attachment,
      };

      if (userIds) {
        dispatch(
          postCommentReplyApprovalAction({
            ...body,
            user_ids: !!userIds.length ? userIds : undefined,
            can_anyone_approve: !userIds.length,
          }),
        );
      } else {
        dispatch(postCommentReplyAction(body));
      }
    },
    [dispatch],
  );

  const handleRefresh = () => {
    if (selectedCategory?.id) {
      localStorage.setItem(
        "SelectedReplyModalState",
        JSON.stringify({
          view: currentView,
          categoryId: selectedCategory?.id,
        }),
      );

      const isTTPlatform =
        replyData.post_preview?.platform_type.id &&
        isTiktokPlatform(replyData.post_preview?.platform_type.id);
      const isTWPlatform =
        replyData.post_preview?.platform_type.id &&
        isTwitterPlatform(replyData.post_preview?.platform_type.id);

      if (selectedCategory?.id === "private_messages" && !isTTPlatform && !isTWPlatform)
        fetchReplies(replyData.author_detail.author_id);
      else fetchReplies(replyData.replying_comment_id);
    }
  };

  return (
    <div
      className={classNames(s.bbReplyModalContentWrapper, {
        [s.bbReplyModalContentWrapperMobile]: isMobile,
      })}
    >
      <UserDetails
        replyData={replyData}
        comment={comment}
        selectedCategory={selectedCategory}
        onChangeCategory={handleChangeCategory}
        onRefresh={handleRefresh}
      />
      <div className={s.bbCategoryWrapper}>
        {!isMobile && !!selectedCategory && !(selectedCategory?.id === "reviews") && (
          <div className={s.bbCategoryTitle}>
            <Breadcrumbs
              currentView={currentView}
              onClick={() => setCurrentView("default")}
              authorName={replyData.author_detail.author_name}
              category={selectedCategory?.id as CategoryType}
            />
          </div>
        )}
        <div className={s.bbCategoryBody}>
          {currentView === "details" ? (
            <MessageList
              filters={filters}
              onReply={postReply}
              replyData={replyData}
              isLoading={repliesFetching}
              isReplyBtnLoading={isReplyPosting}
              onBottomReached={fetchRepliesPage}
              onClose={onClose}
              listId={listId}
            />
          ) : (
            <div className={s.bbDefaultListContainer}>
              <CommentsGridContainer
                filters={selectedCategory?.search_filters}
                providedFetchAction={providedFetchAction}
                view="comment_details"
                showLikeAction={showLikeAction}
                showLinkedInReactionAction={showLinkedInReactionAction}
                showHideAction={showHideAction}
                showDeleteAction={showDeleteAction}
                showReviewAction={showReviewAction}
                showFlagAction={showFlagAction}
                showStarAction={showStarAction}
                showDetailsAction={showDetailsAction}
                showArchiveAction={showArchiveAction}
              />
            </div>
          )}
        </div>
      </div>
      <ReplyModalRightPanel
        filters={filters}
        listId={listId}
        currentView={currentView}
        replyData={replyData}
        zendeskTicket={zendeskTicket}
        onClose={onClose}
      />
    </div>
  );
};

const ReplyModal: FC<IReplyModalProps> = ({
  isOpen,
  filters,
  onClose,
  showLikeAction,
  showHideAction,
  showDeleteAction,
  showLinkedInReactionAction,
  showReviewAction,
  showFlagAction,
  showStarAction,
  showArchiveAction,
  providedFetchAction,
  listId,
}) => {
  const screens = Grid.useBreakpoint();
  const dispatch = useDispatch();
  const { resetTranslation } = useReplyModalContext();

  const isMobile = screens.md === false;

  const commentsNavigationId = useSelector(commentsNavigationIdSelector);
  const replyData = useSelector(repliesSelector);
  const repliesFetching = useSelector(fetchingRepliesSelector);
  const repliesFetched = useSelector(fetchedRepliesSelector);
  const threadRepliesFetching = useSelector(fetchingThreadRepliesSelector);
  const isReplyPosted = useSelector(postedReplySelector);

  const fetchReplies = useCallback(
    commentId => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );

  const clearReplyComments = () => {
    dispatch(clearRepliesCommentListAction());
  };

  const clearReplyingThreadComment = useCallback(
    () => dispatch(clearReplyingThreadCommentAction()),
    [dispatch],
  );

  const nextComment = useCallback(() => {
    const nextCommentId = replyData?.navigation_meta?.next_id;

    if (nextCommentId) {
      resetTranslation();
      fetchReplies(nextCommentId);
      localStorage.removeItem("SelectedReplyModalState");
    }
  }, [fetchReplies, replyData, resetTranslation]);

  const prevComment = useCallback(() => {
    const prevCommentId = replyData?.navigation_meta?.prev_id;

    if (prevCommentId) {
      resetTranslation();
      fetchReplies(prevCommentId);
      localStorage.removeItem("SelectedReplyModalState");
    }
  }, [fetchReplies, replyData, resetTranslation]);

  useKeyPress(keyCodes.ArrowRight, () => {
    nextComment();
  });

  useKeyPress(keyCodes.ArrowLeft, () => {
    prevComment();
  });

  useEffect(() => {
    if (isReplyPosted && localStorage.getItem("AutoNavigation") === "true") {
      nextComment();
    }
    // eslint-disable-next-line
  }, [isReplyPosted]);

  useEffect(() => {
    if (!replyData || !repliesFetched) return;

    const { post_preview, post_placement } = replyData;

    if (!post_preview || post_placement !== PostPlacementEnum.DM) return;

    const isTiktokDM = isTiktokPlatform(post_preview.platform_type.id);
    const isTwitterDM = isTwitterPlatform(post_preview.platform_type.id);

    if (isTiktokDM || isTwitterDM) dispatch(getRepliesAttachmentsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, repliesFetched]);

  const _onClose = () => {
    onClose();
    resetTranslation();
    clearReplyComments();
    clearReplyingThreadComment();
    localStorage.removeItem("SelectedReplyModalState");
  };

  return (
    <Modal
      centered
      hideFooter
      hideHeader
      width={1216}
      open={isOpen}
      onCancel={_onClose}
      classes={{
        root: s.bbReplyModal,
        modal: s.bbReplyModalContent,
        body: s.bbReplyModalBody,
      }}
    >
      {!isMobile && replyData?.navigation_meta?.prev_id && !repliesFetching && (
        <div
          onClick={prevComment}
          className={classNames(s.bbReplyModalArrow, s.bbReplyModalArrowLeft)}
        >
          <ChevronLeftIcon />
        </div>
      )}
      {repliesFetching || threadRepliesFetching ? (
        <div className={s.bbReplyModalLoading}>
          <Loading type="bb" isLoading />
        </div>
      ) : !!replyData && !!filters ? (
        <ReplyModalContent
          replyData={replyData}
          filters={filters}
          onClose={_onClose}
          listId={listId}
          showLikeAction={showLikeAction}
          showLinkedInReactionAction={showLinkedInReactionAction}
          showHideAction={showHideAction}
          showDeleteAction={showDeleteAction}
          showReviewAction={showReviewAction}
          showFlagAction={showFlagAction}
          showStarAction={showStarAction}
          showArchiveAction={showArchiveAction}
          providedFetchAction={providedFetchAction}
        />
      ) : null}
      {!isMobile && replyData?.navigation_meta?.next_id && !repliesFetching && (
        <div
          onClick={nextComment}
          className={classNames(s.bbReplyModalArrow, s.bbReplyModalArrowRight)}
        >
          <ChevronLeftIcon />
        </div>
      )}
    </Modal>
  );
};

export default ReplyModal;
