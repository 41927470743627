import { t } from "../../../App";

import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";

export const TABLE_PAGE_SIZE = 25;

export const getTableColumns = ({ isMobile }: { isMobile: boolean }): ISimpleTableColumn[] => {
  const firstColumn = {
    name: t("components:listen:sources:table:columns:source"),
    id_name: "source_label",
    colSpan: isMobile ? 12 : 7,
  };

  if (isMobile) return [firstColumn];

  return [
    firstColumn,
    {
      name: t("components:listen:sources:table:columns:type"),
      id_name: "type_id",
      colSpan: 4,
    },
    {
      name: t("components:listen:sources:table:columns:asset"),
      id_name: "asset_name",
      colSpan: 5,
    },
    {
      name: t("components:listen:sources:table:columns:status"),
      id_name: "asset_status",
      colSpan: 4,
    },
  ];
};
