import React from "react";
import classNames from "classnames";

import { Grid } from "antd";
import Breadcrumbs from "@components/_common/breadcrumbs";

import s from "./Header.module.less";

import { IHeaderProps } from "./Header.types";

export const Header: React.FC<IHeaderProps> = ({ children, className }) => {
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  return (
    <div
      className={classNames(s.bbHeader, {
        className,
        [s.bbHeaderMobile]: isMobile,
      })}
    >
      <Breadcrumbs />
      <div className={s.bbHeaderChildrenWrapper}>{children}</div>
    </div>
  );
};
