import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PostBased } from "@components/_common/PostBased/PostBased";
import { CareReviewTag } from "@components/_common/CareReviewTag/CareReviewTag";

import { meSelector } from "@store/me/selectors";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";

export const useNotificationFilters = (filterValues: IFilters, hideCareReviewTag?: boolean) => {
  const { t } = useTranslation();
  const me = useSelector(meSelector);

  const filters = useGetDefaultFiltersConfig(t, true);

  const [careReviewTag, setCareReviewTag] = useState(false);

  const keywordsIdx = filters.findIndex(obj => obj.name === "keyword_query");
  const postIdsIdx = filters.findIndex(obj => obj.name === "post_ids");
  const platformTypes = filters.findIndex(obj => obj.name === "platform_types");

  if (keywordsIdx > -1) {
    filters[keywordsIdx].props = {
      ...filters[keywordsIdx].props,
      maxChipCount: 2,
    };
  }

  if (postIdsIdx > -1) {
    filters.splice(postIdsIdx + 1, 0, {
      name: "is_post_based",
      type: "asset",
      component: PostBased,
      label: "",
      props: {
        checked: !!filterValues?.is_post_based,
      },
    });
  }

  if (platformTypes > -1) {
    filters[platformTypes].props = {
      ...filters[platformTypes].props,
      isDisabled: () => false,
    };
  }

  if (!hideCareReviewTag && !!me?.client_data.care_review_tag_options?.length) {
    filters.unshift({
      name: "care_review_tags",
      type: "comment",
      component: CareReviewTag,
      label: "",
      props: {
        onCareReviewTagChange: setCareReviewTag,
      },
    });
  }

  useEffect(() => {
    if (hideCareReviewTag) return;
    if (!!filterValues?.care_review_tags?.length) setCareReviewTag(true);
    // eslint-disable-next-line
  }, [!!filterValues?.care_review_tags?.length]);

  return {
    careReviewTagChecked: careReviewTag,
    notificationFilters: filters.filter(({ name }) => name !== "data_range_option"),
  };
};
