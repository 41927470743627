import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import WorkflowActions from "./WorkflowActions";
import WorkflowItemLabel from "./WorkflowItemLabel";
import { Card, Toggle, ButtonPermissionGuard } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesExplorerLinkSelector,
} from "@store/shortenedSearches/selectors";

import { enableWorkflowAction, disableWorkflowAction } from "@store/workflows/actions";
import { postShortenedSearchesAction } from "@store/shortenedSearches/actions";

import s from "./WorkflowItem.module.less";

import {
  IWorkflowItemProps,
  DESCRIPTION_MAX_LENGTH,
  SUPPORTED_WORKFLOW_CATEGORIES,
} from "./helpers";
import { cropText } from "@bbdevcrew/bb_ui_kit_fe";
import { getWorkflowItemTitle } from "../helpers";
import usePermissions from "@utils/usePermissions";
import { WorkflowCategoryIconMap } from "../menu/helpers";

const WorkflowItem: FC<IWorkflowItemProps> = ({ item, onEditClick, onDeleteClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const permissions = usePermissions();

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const canViewOrEdit = useMemo(
    () => SUPPORTED_WORKFLOW_CATEGORIES.includes(item.category) && item.is_editable,
    [item.category, item.is_editable],
  );

  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
    }
  }, [postShortenedSearchesFetched, shortenedSearchesExplorerLink]);

  const closeActionsDropdown = () => setIsActionsDropdownOpen(false);

  const enableWorkflow = useCallback(
    (id: string) => dispatch(enableWorkflowAction(id)),
    [dispatch],
  );

  const disableWorkflow = useCallback(
    (id: string) => dispatch(disableWorkflowAction(id)),
    [dispatch],
  );

  const onWorkflowToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) enableWorkflow(item.id);
    else disableWorkflow(item.id);
  };

  const onCardClick = () => {
    if (!isActionsDropdownOpen && canViewOrEdit) {
      onEditClick(closeActionsDropdown);
    }
  };

  const goToExplore = useCallback(
    () => dispatch(postShortenedSearchesAction({ filters: item?.trigger?.filters || {} })),
    // eslint-disable-next-line
    [dispatch],
  );

  const Icon = WorkflowCategoryIconMap[item.category];

  return (
    <>
      <Card
        className={classNames(s.bbWorkflowItemContainer, {
          [s.bbCanViewOrEdit]: canViewOrEdit,
        })}
        data-cy="workflows-item"
        onClick={onCardClick}
      >
        <div className={s.bbWorkflowItem}>
          {item.show_enable_checkbox && (
            <div className={s.bbWorkflowItemToggle} onClick={event => event.stopPropagation()}>
              <ButtonPermissionGuard
                hasPermission={!!permissions["manageWorkflows"]}
                message={t("permissions:editWorkflows")}
              >
                <Toggle
                  id={`enabled-${item.id}`}
                  checked={item.enabled}
                  onChange={onWorkflowToggle}
                  data-cy="workflow-toggle"
                />
              </ButtonPermissionGuard>
            </div>
          )}
          <div
            className={classNames(
              s.bbWorkflowItemHeader,
              s[`bbWorkflowItemHeader-${item.category}`],
            )}
          >
            <div className={s.bbWorkflowItemCategoryIcon}> {Icon ? <Icon /> : null}</div>
            <div className={s.bbWorkflowItemCategory}>{getWorkflowItemTitle(item.category)}</div>
            <div className={s.bbWorkflowItemTitle} data-cy="workflow-item-title">
              {item.title}
            </div>
            <div className={s.bbWorkflowItemDescription}>
              {item.description &&
                item.description.trim() !== "-" &&
                cropText(item.description, DESCRIPTION_MAX_LENGTH)}
            </div>
          </div>
          <div className={s.bbWorkflowItemLabelWrapper}>
            <div className={s.bbWorkflowItemLabel}>
              {item.labels.map(label => (
                <WorkflowItemLabel key={label.id} label={label} />
              ))}
            </div>
            {canViewOrEdit && (
              <WorkflowActions
                onEditClick={() => {
                  onEditClick(closeActionsDropdown);
                }}
                onDeleteClick={() => {
                  onDeleteClick(closeActionsDropdown);
                }}
                onExploreClick={goToExplore}
                isActionsDropdownOpen={isActionsDropdownOpen}
                setIsActionsDropdownOpen={setIsActionsDropdownOpen}
                category={item.category}
              />
            )}
          </div>
        </div>
      </Card>
      {/* Temporarily disabled the details modal, https://brandbastion.atlassian.net/browse/DEV-11958 */}
      {/* {enableDetailModal && (
        <Modal
          responsive
          centered
          theme="light"
          title={item.title}
          hideFooter
          
          open={detailModalOpen}
          onCancel={() => setDetailModalOpen(false)}
          noPadding
          width={800}
        >
          <WorkflowItemDetails {...item} />
        </Modal>
      )} */}
    </>
  );
};

export default WorkflowItem;
