import React, { FC, Fragment } from "react";

import { Chip, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./WorkflowItem.module.less";

import { IWorkflowLabelProps } from "./helpers";

import { DiamondIcon, TimeIcon, WordsIcon } from "@assets/index";

const WorkflowItemLabel: FC<IWorkflowLabelProps> = ({ label: labelItem }) => {
  const { id, label } = labelItem;

  const renderLabel = () => {
    switch (id) {
      case "preset":
        return <Chip _size="xxs" text={label} />;
      case "fully_managed":
      case "keyword_based":
      case "time_frame_based":
      case "x_time":
      case "up_to_1_hour":
        return (
          <div className={s.bbTagLabel}>
            <ChipIcon
              _size="xxs"
              _type="grey"
              text={label}
              icon={
                id === "keyword_based" ? (
                  <WordsIcon />
                ) : id === "fully_managed" ? (
                  <DiamondIcon />
                ) : (
                  <TimeIcon />
                )
              }
              iconPosition="left"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return <Fragment key={id}>{renderLabel()}</Fragment>;
};

export default WorkflowItemLabel;
