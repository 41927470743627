import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";

import {
  Card,
  Help,
  Button,
  Tooltip,
  TabPane,
  Loading,
  TabsScrollableWithArrows,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Grid } from "antd";
import ChartLabel from "./ChartLabel";
import DownloadCSV from "../../../_common/DownloadCSV";
import BreakdownChartLegend from "./breakdownChartLegend";
import BreakdownChartTooltip from "./breakdownChartTooltip";
import { BreakdownChart } from "../../../_common/BreakdownChart/BreakdownChart";

import { filterDataSelector } from "@store/filters/selectors";

import s from "./CommentBreakdown.module.less";

import {
  getCSVChartData,
  getCSVTableHeaders,
  LABEL_SMALL,
  LABEL_LARGE,
  BAR_PADDING,
  BAR_HEIGHT,
  CHART_MAX_HEIGHT,
  CHART_HEIGHT_OFFSET,
} from "./helpers";
import { useYTSelected } from "@utils/useYTselected";
import { IBreakdownUnit } from "@store/aggregations/types";
import { ICommentBreakdownProps } from "./CommentBreakdown.type";
import { IChartLabelClickEvent } from "../../_common/topInfluencers/topInfluencersChart/TopInfluencersChart.type"; // eslint-disable-line max-len

import { DownloadIcon } from "@assets/index";
import { LoadingOutlined } from "@ant-design/icons";

const CommentBreakdown: FC<ICommentBreakdownProps> = ({
  isLoading,
  data: breakdownUnits,
  filterToSpecificItem,
  className,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const filters = useSelector(filterDataSelector);
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);
  const chartLabelWidth = screens.md ? LABEL_LARGE : LABEL_SMALL;
  const [activeTabKey, setActiveTabKey] = useState("platform_type");

  const getSelectedItemLabel = () => breakdownUnits?.find(item => item.id === activeTabKey)?.label;

  const onYAxisLabelClick = (clickedItem: IChartLabelClickEvent) => {
    const currentSelectedItem = breakdownUnits.find(
      breakdownUnit => breakdownUnit.id === activeTabKey,
    );

    if (currentSelectedItem && clickedItem?.index !== undefined) {
      const selectedItemValue = currentSelectedItem["items"][clickedItem?.index];

      // Custom tags have to follow this structure to be properly shown in the filters pane
      if (activeTabKey === "custom_tags") {
        filterToSpecificItem({
          comment_tags: [`custom_tag::${selectedItemValue.id}`],
        });
      } else {
        filterToSpecificItem({
          [currentSelectedItem.search_param as string]: [selectedItemValue.id],
        });
      }
    }
  };

  const getActiveTabName = () => breakdownUnits.find(unit => unit.id === activeTabKey)?.label || "";

  const getChartHeight = (breakdownUnit: IBreakdownUnit) => {
    const height = breakdownUnit.items.length * (BAR_HEIGHT + BAR_PADDING);

    return height < CHART_MAX_HEIGHT ? CHART_MAX_HEIGHT - CHART_HEIGHT_OFFSET : height;
  };

  return (
    <Card
      id="card-breakdown"
      className={classNames(s.bbBreakdownCard, className)}
      data-cy="search-breakdown-chart"
      data-stonly-target="intelligence-search__breakdown"
      headerTitle={t("components:breakdown:title", {
        selected: getSelectedItemLabel(),
      })}
      headerTitleSuffix={
        <span className={s.bbHeaderTitleSuffix}>
          <Help tooltipProps={{ title: t("components:breakdown:info") }} />
        </span>
      }
      headerAction={
        <>
          {
            <Tooltip title={isYTFilterSelected ? t("pages:insights:ytNoDownload") : ""}>
              <span>
                <Button className={s.bbDownloadButton} disabled={isYTFilterSelected}>
                  <DownloadCSV
                    icon={<DownloadIcon />}
                    fileNamePrefix="breakdown"
                    disabled={isYTFilterSelected}
                    data={getCSVChartData(breakdownUnits, activeTabKey)}
                    tableHeaders={getCSVTableHeaders(getActiveTabName())}
                  />
                </Button>
              </span>
            </Tooltip>
          }
        </>
      }
    >
      <div className={s.bbBreakdownContainer}>
        {isLoading ? (
          <div className={s.bbBreakdownLoading}>
            <Loading
              isLoading
              size="large"
              tip={t("pages:loading:spinText")}
              indicator={<LoadingOutlined color="primary" style={{ fontSize: 24 }} />}
            />
          </div>
        ) : (
          <TabsScrollableWithArrows
            activeKey={activeTabKey}
            onChange={key => setActiveTabKey(key)}
            defaultActiveKey={breakdownUnits[0]?.id}
          >
            {breakdownUnits?.map(breakdownUnit => (
              <TabPane
                tab={
                  <div
                    data-stonly-target={"intelligence-search__breakdown--tab-" + breakdownUnit.id}
                  >
                    {breakdownUnit.label}
                  </div>
                }
                tabKey={breakdownUnit.id}
                key={breakdownUnit.id}
              >
                <ReactResizeDetector
                  handleWidth
                  refreshRate={2000}
                  refreshMode={"throttle"}
                  refreshOptions={{ leading: true, trailing: true }}
                >
                  {(resizeProps: { width: number }) => (
                    <React.Fragment key={breakdownUnit.id}>
                      <div
                        key={breakdownUnit.id}
                        className={s.bbBreakdownChartContainer}
                        style={{ height: CHART_MAX_HEIGHT }}
                      >
                        <BreakdownChart
                          data={breakdownUnit}
                          height={getChartHeight(breakdownUnit)}
                          barSize={BAR_HEIGHT}
                          labelWidth={chartLabelWidth}
                          width={resizeProps.width || 0}
                          onYAxisLabelClick={onYAxisLabelClick}
                          customTooltip={<BreakdownChartTooltip />}
                          generateYLabel={props => (
                            <ChartLabel
                              type={activeTabKey}
                              labelTickProps={props}
                              breakdownUnit={breakdownUnit}
                              tooltipLabel={getActiveTabName()}
                              chartLabelWidth={chartLabelWidth}
                            />
                          )}
                        />
                      </div>
                      <BreakdownChartLegend />
                    </React.Fragment>
                  )}
                </ReactResizeDetector>
              </TabPane>
            ))}
          </TabsScrollableWithArrows>
        )}
      </div>
    </Card>
  );
};

export default CommentBreakdown;
