import React from "react";
import { useTranslation } from "react-i18next";

import WeekdaysSelect from "@components/_common/weekdaysSelect/WeekdaysSelect";
import { TagWorkflowTimePicker } from "./TagWorkflowTimePicker";
import Timezone from "@components/_common/Timezone";

import s from "./TagWorkflowTrigger.module.less";

import { defaultSelectedDays } from "../../actions/emailAlertWorkflowAction/emailAlertWorkflowThreshold/EmailAlertWorkflowThreshold.helpers"; // eslint-disable-line max-len

import { ITimeFrameTrigger } from "@store/workflows/types";
import { ITimeFrameTriggerValue } from "./TagWorkflowTrigger.types";
import { IFormComponent } from "@components/care/workflows/types";

export const TimeFrameTrigger: React.FC<IFormComponent<ITimeFrameTrigger>> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChange =
    <K extends keyof ITimeFrameTrigger>(key: K) =>
    (newValue: ITimeFrameTriggerValue<K>) => {
      const updatedValue = {
        ...(value ? value : {}),
        [key]: newValue,
      };
      onChange?.(updatedValue as ITimeFrameTrigger);
    };

  return (
    <>
      <div className={s.bbTagTriggerTimeFrame}>
        <span>{t("generic:between")}</span>
        <TagWorkflowTimePicker
          value={value?.start_time}
          onChange={handleChange("start_time")}
          name="start_time"
        />
        <span>{t("generic:and")}</span>
        <TagWorkflowTimePicker
          value={value?.end_time}
          onChange={handleChange("end_time")}
          name="end_time"
        />
      </div>
      <div className={s.bbTagTriggerWeekdays}>
        <span>{t("pages:workflows:form:triggers:time_frame:weekdays:label")}</span>
        <WeekdaysSelect
          selectedDays={value?.week_days || defaultSelectedDays}
          onChange={handleChange("week_days")}
        />
      </div>
      <p className={s.bbTagTriggerTimeZoneLabel}>{t("components:profileUser:timezone:label")}</p>
      <Timezone
        className={s.bbTagTriggerTimeZone}
        onChange={handleChange("timezone")}
        value={value?.timezone}
      />
    </>
  );
};
