import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Filters, Button, IFormFilterRequest } from "@bbdevcrew/bb_ui_kit_fe";
import ExpansionPanel, { ExpansionPanelBadge } from "../../ExpansionPanel";

import { clientDataSelector } from "@store/me/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./EmailAlertOrZendeskWorkflowTrigger.module.less";

import {
  debounce,
  IFilters,
  IFormHandler,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import { getFetchValuesFieldName } from "@utils/filters";
import { useNotificationFilters } from "./EmailAlertOrZendeskWorkflowTrigger.helpers";
import {
  IEmailAlertOrZendeskWorkflowTrigger,
  IFiltersPanelProps,
} from "./EmailAlertOrZendeskWorkflowTrigger.types";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const FiltersPanel: React.FC<IFiltersPanelProps> = ({
  title,
  value,
  initialOpen = true,
  onInternalChange,
}) => {
  const { t } = useTranslation();
  const filtersRef = useRef<IFormHandler>(null);
  const dispatch = useDispatch();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const { notificationFilters } = useNotificationFilters(value);

  const [fullHeight, setFullHeight] = React.useState(false);
  const [open, setOpen] = React.useState(initialOpen);

  const clientData = useSelector(clientDataSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (filtersRef?.current && value) {
      filtersRef.current.initializeFormValues(value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ExpansionPanel title={title} open={open} onToggle={() => setOpen(!open)}>
      <Filters
        ref={filtersRef}
        form={filtersForm}
        onChangeCallback={onInternalChange}
        filtersConfig={notificationFilters}
        onFilter={() => {}} // eslint-disable-line
        clientPlatformTypes={clientData?.platform_types || []}
        className={classNames(s.bbAppFiltersFormWrapper, {
          [s.bbAppFiltersFormWrapperFullHeight]: fullHeight,
        })}
        autocompleteOptions={autocompleteOptions}
        getAutocompleteOptions={getAutocompleteOptions}
        sanitizeFieldName={getFetchValuesFieldName}
        disableExclusionOptions
      />
      <Button
        className={s.bbViewMoreButton}
        _type="link"
        onClick={() => setFullHeight(!fullHeight)}
      >
        {t(`pages:workflows:form:triggers:email_alert:${fullHeight ? "viewLess" : "viewMore"}`)}
        {fullHeight ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
    </ExpansionPanel>
  );
};

export const EmailAlertOrZendeskWorkflowTrigger: React.FC<IEmailAlertOrZendeskWorkflowTrigger> = ({
  title,
  form,
}) => {
  const { t } = useTranslation();

  const value = form.getFieldValue("trigger")?.filters || {};
  const excludedValue = form.getFieldValue("trigger")?.excluded || {};

  const onInternalChange = (type: "filters" | "excluded") => (filters: IFilters) => {
    const currentValue = form.getFieldValue("trigger") || {};

    form.setFields([
      {
        name: ["trigger", "filters"],
        value:
          type === "filters"
            ? filters
            : {
                ...currentValue.filters,
                exclusion_filters: filters,
              },
      },
    ]);
  };

  return (
    <div className={s.bbWorkflowEmailFilters}>
      {!!title && <div className={s.bbWorkflowEmailFiltersTitle}>{title}</div>}
      <FiltersPanel
        title={
          <>
            <ExpansionPanelBadge>
              {t("components:filters:workflows:filters:badge")}
            </ExpansionPanelBadge>
            {t("components:filters:workflows:filters:title")}
          </>
        }
        value={value}
        onInternalChange={onInternalChange("filters")}
        initialOpen={true}
      />
      <FiltersPanel
        title={
          <>
            <ExpansionPanelBadge danger>
              {t("components:filters:workflows:excluded:badge")}
            </ExpansionPanelBadge>
            {t("components:filters:workflows:excluded:title")}
          </>
        }
        value={excludedValue}
        onInternalChange={onInternalChange("excluded")}
        initialOpen={false}
      />
    </div>
  );
};
