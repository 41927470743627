import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import DateTick from "./DateTick";
import ReactResizeDetector from "react-resize-detector";
import { Card, Help, Button, Tooltip, LineChart } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Timeline.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import {
  xAxisProps,
  yAxisProps,
  CHART_MARGIN,
  legendTooltipStyle,
  getTimelineDisplayData,
  HEIGHT,
  CONTAINER_HEIGHT,
} from "./TimelineChart.helpers";
import { dayjs, IAxisTick } from "@bbdevcrew/bb_ui_kit_fe";
import { useYTSelected } from "@utils/useYTselected";
import { ITimelineProps } from "./TimelineChart.types";

const Timeline: FC<ITimelineProps> = ({
  data,
  filters,
  filterToSpecificItem,
  lines,
  downloadCSVButton,
  className,
  headerClassName,
  chartHeight = HEIGHT,
  customLegend,
  title,
}) => {
  const { t } = useTranslation();
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const XAxisProps = {
    ...xAxisProps,
    tick: (tick: IAxisTick) => <DateTick data={data} tick={tick} />,
  };

  const onLegendClick = (clickedItem?: string) => {
    if (clickedItem)
      filterToSpecificItem({
        sentiments: [clickedItem],
      });
  };

  const onZoom = (dates: string[]) => {
    const [from, to] = dates;

    filterToSpecificItem({
      data_range_option: "custom",
      start_time: dayjs(from).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      end_time: dayjs(to).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    });
  };

  return (
    <Card
      className={classNames(s.bbTimeline, className)}
      headerClassName={headerClassName}
      data-cy="search-timeline-chart"
      data-stonly-target="intelligence-search__timeline"
      headerTitle={title || t("components:timeline:title")}
      headerTitleSuffix={
        <Help
          tooltipProps={{
            style: { width: 380 },
            title: t("components:timeline:tooltips:cardTooltip"),
          }}
        />
      }
    >
      <ReactResizeDetector
        handleWidth
        refreshRate={2000}
        refreshMode={"throttle"}
        refreshOptions={{
          leading: true,
          trailing: true,
        }}
      >
        {(props: { width: number }) => {
          const WIDTH = (props.width || 20) - 20;

          return (
            <div className={s.bbPanelRootCard} style={{ height: CONTAINER_HEIGHT }}>
              <div className={s.bbDownloadLineChart}>
                <Tooltip
                  title={isYTFilterSelected ? t("pages:insights:ytNoDownload") : ""}
                  placement="bottom"
                >
                  <span>
                    {downloadCSVButton && (
                      <Button className={s.bbDownloadButton} disabled={isYTFilterSelected}>
                        {downloadCSVButton}
                      </Button>
                    )}
                  </span>
                </Tooltip>
              </div>
              <div className={s.bbLineChartContainer}>
                <LineChart
                  grid
                  xAxis
                  yAxis
                  legend
                  tooltip
                  isZoomable
                  width={WIDTH}
                  height={chartHeight}
                  renderLegend={customLegend}
                  showPercentages
                  onZoom={onZoom}
                  lines={lines}
                  margin={CHART_MARGIN}
                  xAxisProps={XAxisProps}
                  yAxisProps={yAxisProps}
                  percentagePrecision={1}
                  legendTooltipDelayTime={0.1}
                  onClickLegend={onLegendClick}
                  data={getTimelineDisplayData(data)}
                  legendTooltipStyle={legendTooltipStyle}
                  zoomBtnText={t("generic:zoomOut")}
                  total={{
                    name: t("components:searchPanel:charts:total"),
                    color: palette.colorBlue900,
                  }}
                />
              </div>
            </div>
          );
        }}
      </ReactResizeDetector>
    </Card>
  );
};

export default Timeline;
