import React from "react";

import { ChipType, ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AlertsTable.module.less";

import { t } from "../../../App";

import { IWorkflowEmailAlertAction } from "@store/workflows/types";
import { EmailAlertRangeEnum } from "@store/workflows/types";

import {
  AlertTriangleIcon,
  CalendarIcon,
  CommentIcon,
  PostIcon,
  TargetIcon,
  TargetAltIcon,
  CircleFilledIcon,
  ExclamationIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { AlertRiskLevelType } from "@store/alerts/types";

export const TABLE_PAGE_SIZE = 20;

export const getTableColumns = (isMobile: boolean): ISimpleTableColumn[] => {
  if (isMobile) {
    return [
      {
        name: t("components:listen:alerts:columns:alert"),
        id_name: "notification_name",
        colSpan: 14,
        isSortable: true,
        className: s.bbAlertsTableCol,
        prefix: (
          <i className={s.bbAlertsTableColIcon}>
            <AlertTriangleIcon />
          </i>
        ),
      },
      {
        name: t("components:listen:alerts:columns:dateSent"),
        id_name: "created_time",
        colSpan: 10,
        isSortable: true,
        className: s.bbAlertsTableCol,
        prefix: (
          <i className={s.bbAlertsTableColIcon}>
            <CalendarIcon />
          </i>
        ),
      },
    ];
  }

  return [
    {
      name: t("components:listen:alerts:columns:alert"),
      id_name: "notification_name",
      colSpan: 4,
      isSortable: true,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <AlertTriangleIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:dateSent"),
      id_name: "created_time",
      colSpan: 3,
      isSortable: true,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <CalendarIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:status"),
      id_name: "status",
      colSpan: 3,
      isSortable: false,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <CircleFilledIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:riskLevel"),
      id_name: "risk_level",
      colSpan: 3,
      className: s.bbAlertsTableCol,

      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <ExclamationIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:volume"),
      id_name: "volume",
      colSpan: 2,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <PostIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:associatedPosts"),
      id_name: "posts",
      colSpan: 3,
      isSortable: false,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <CommentIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:platforms"),
      id_name: "platform_types",
      colSpan: 3,
      isSortable: true,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <TargetAltIcon />
        </i>
      ),
    },
    {
      name: t("components:listen:alerts:columns:impactArea"),
      id_name: "impact_area",
      colSpan: 3,
      isSortable: true,
      className: s.bbAlertsTableCol,
      prefix: (
        <i className={s.bbAlertsTableColIcon}>
          <TargetIcon />
        </i>
      ),
    },
  ];
};

export const getFrequency = (alert: IWorkflowEmailAlertAction) => {
  if (alert.range_type === EmailAlertRangeEnum.DYNAMIC) {
    return t("components:listen:alerts:frequency:dynamic");
  }

  return t("components:listen:alerts:frequency:range", {
    threshold: alert.threshold,
    range_number: alert.range_number,
    range_type: alert.range_type,
  });
};

export const riskChipTypeMap: Record<AlertRiskLevelType, ChipType> = {
  none: "risk-none",
  low: "risk-low",
  moderate: "risk-moderate",
  high: "risk-high",
  critical: "risk-critical",
};

export const riskLeveloptions = ["critical", "high", "moderate", "low", "none"];

export const getRiskOptions = () => {
  return riskLeveloptions.map(risk => ({
    label: t(`components:listen:alerts:risk:${risk}`),
    value: risk,
  }));
};

export const statusOptions = ["active", "archived"];

export const getStatusOptions = () => {
  return statusOptions.map(status => ({
    label: t(`components:listen:alerts:status:${status}`),
    value: status,
  }));
};
