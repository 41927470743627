import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useState } from "react";

import { Form } from "antd";
import { SavedReplySelect } from "./SavedReplySelect";
import InputNumber from "@components/_common/InputNumber";
import { Checkbox, FormErrorMessage, Help, Label, Tooltip, Icon } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./ReplyWorkflowAction.module.less";

import { IPrivateReplyWorkflowActionProps } from "./ReplyWorkflowAction.types";
import { MAX_MINUTES, MIN_MINUTES, getActionFormItemName } from "./ReplyWorkflowAction.helpers";

import { SendIcon, InformationIcon, HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ReplyPrivatelyAction = ({ form, item, hideTitle }: IPrivateReplyWorkflowActionProps) => {
  const { t } = useTranslation();

  const [replyOnlyFirstPrivateMessage, setReplyOnlyFirstPrivateMessage] = useState(
    item?.reply ? item?.reply?.reply_only_first_private_message : true,
  );
  const [overrideWorkflowName, setOverrideWorkflowName] = useState(
    item?.reply ? item?.reply?.overwrite_workflow_name_listing : true,
  );
  const [hideForExternalUsers, setHideForExternalUsers] = useState(
    item?.reply ? item?.reply?.hide_for_external_users : true,
  );

  const me = useSelector(meSelector);

  const onReplyOnlyFirstPrivateMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReplyOnlyFirstPrivateMessage(e.target.checked);
    form.setFieldsValue({
      action: {
        ...form.getFieldsValue().action,
        reply_only_first_private_message: e.target.checked,
      },
    });
  };

  const onOverrideWorkflowNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOverrideWorkflowName(e.target.checked);
    form.setFieldsValue({
      action: {
        ...form.getFieldsValue().action,
        overwrite_workflow_name_listing: e.target.checked,
      },
    });
  };

  const onHideForExternalUsersChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHideForExternalUsers(e.target.checked);
    form.setFieldsValue({
      action: {
        ...form.getFieldsValue().action,
        hide_for_external_users: e.target.checked,
      },
    });
  };

  return (
    <div className={s.bbReplyWorkflowAction}>
      {!hideTitle && (
        <div className={s.bbReplyWorkflowActionTitle}>
          <div className={s.bbReplyWorkflowActionTitleIcon}>
            <SendIcon />
          </div>
          <div className={s.bbReplyWorkflowActionTitleText}>
            {t("pages:workflows:form:actions:reply:replyPrivatelyTab:title")}
          </div>
        </div>
      )}

      <div className={s.bbReplyWorkflowActionInline}>
        <div className={s.bbWaitInputWrapper}>
          <Form.Item
            label={t("pages:workflows:form:actions:reply:replyPrivatelyTab:minutesSelectTitle")}
            name={getActionFormItemName("delay_private_reply")}
            rules={[
              {
                required: true,
                message: (
                  <FormErrorMessage>
                    {t("pages:workflows:form:actions:reply:replyPrivatelyTab:minutesError")}
                  </FormErrorMessage>
                ),
              },
              {
                validator: (_, value) => {
                  if (value === undefined || value === null) {
                    return Promise.resolve();
                  }
                  if (value < MIN_MINUTES || value > MAX_MINUTES) {
                    return Promise.reject(
                      <FormErrorMessage>
                        {t("pages:workflows:form:actions:reply:replyPrivatelyTab:minutesValError", {
                          min: MIN_MINUTES,
                          max: MAX_MINUTES,
                        })}
                      </FormErrorMessage>,
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            className={s.bbWaitInputContainer}
          >
            <InputNumber min={MIN_MINUTES} max={MAX_MINUTES} />
          </Form.Item>
        </div>

        <div className={s.bbReplyPrivatelySelectWrapper}>
          <div className={s.bbReplyPubliclySelectLabel}>
            {t("pages:workflows:form:actions:reply:replyPubliclyTab:selectTitle")}
          </div>
          <Form.Item
            name={getActionFormItemName("private_reply_template_id")}
            rules={[
              {
                required: true,
                message: (
                  <FormErrorMessage>
                    {t("pages:workflows:form:actions:reply:replyPubliclyTab:selectError")}
                  </FormErrorMessage>
                ),
              },
            ]}
          >
            <SavedReplySelect />
          </Form.Item>
        </div>
      </div>

      <div className={s.bbReplyPrivatelyDisclaimer}>
        {t("pages:workflows:form:actions:reply:replyPrivatelyTab:disclaimer")}
        <Tooltip
          title={t("pages:workflows:form:actions:reply:replyPrivatelyTab:disclaimerTooltip")}
          overlayInnerStyle={{ width: 440 }}
        >
          <InformationIcon />
        </Tooltip>
      </div>

      <Form.Item
        name={getActionFormItemName("reply_only_first_private_message")}
        className={s.bbReplyPrivatelyCheckbox}
      >
        <>
          <Checkbox
            _size="sm"
            id="reply_only_first_private_message"
            name="reply_only_first_private_message"
            checked={replyOnlyFirstPrivateMessage}
            onChange={onReplyOnlyFirstPrivateMessageChange}
            className={s.bbCheckbox}
          />
          <Label
            htmlFor="reply_only_first_private_message"
            className={s.bbCheckboxLabel}
            text={t("pages:workflows:form:actions:reply:replyPrivatelyTab:checkboxLabel")}
          />
          <Help
            tooltipProps={{
              style: { width: 420 },
              title: t("pages:workflows:form:actions:reply:replyPrivatelyTab:checkboxTooltip"),
            }}
          />
        </>
      </Form.Item>

      <div className={s.bbReplyPubliclyLimits}>
        {t("pages:workflows:form:actions:reply:replyPrivatelyTab:limits:title")}
        <ul>
          <li className={s.bbReplyPubliclyLimit}>
            <div className={s.bbReplyPubliclyLimitWithTooltip}>
              {t("pages:workflows:form:actions:reply:replyPrivatelyTab:limits:limit1")}
              <Tooltip
                overlayStyle={{ zIndex: 100001 }}
                title={t("pages:workflows:form:actions:reply:intervalTooltip", {
                  count: 2,
                })}
              >
                <Icon icon={HelpIcon} _size="sm" />
              </Tooltip>
            </div>
          </li>
          <li className={s.bbReplyPubliclyLimit}>
            {t("pages:workflows:form:actions:reply:replyPrivatelyTab:limits:limit2")}
          </li>
          <li className={s.bbReplyPubliclyLimit}>
            {t("pages:workflows:form:actions:reply:replyPrivatelyTab:limits:limit3")}
          </li>
        </ul>
      </div>

      {me?.is_bb_backoffice_user && (
        <div className={s.bbAdvancedOptions}>
          <div className={s.bbAdvancedOptionsTitle}>
            {t("pages:workflows:form:actions:reply:replyPrivatelyTab:advancedOptionsTitle")}
          </div>

          <Form.Item
            name={getActionFormItemName("overwrite_workflow_name_listing")}
            className={s.bbReplyPrivatelyCheckbox}
          >
            <>
              <Checkbox
                _size="sm"
                id="overwrite_workflow_name_listing"
                name="overwrite_workflow_name_listing"
                checked={overrideWorkflowName}
                onChange={onOverrideWorkflowNameChange}
                className={s.bbCheckbox}
              />
              <Label
                htmlFor="overwrite_workflow_name_listing"
                className={s.bbCheckboxLabel}
                text={t(
                  "pages:workflows:form:actions:reply:replyPrivatelyTab:overrideWorkflowName",
                )}
              />
            </>
          </Form.Item>

          <Form.Item
            name={getActionFormItemName("hide_for_external_users")}
            className={s.bbReplyPrivatelyCheckbox}
          >
            <>
              <Checkbox
                _size="sm"
                id="hide_for_external_users"
                name="hide_for_external_users"
                checked={hideForExternalUsers}
                onChange={onHideForExternalUsersChange}
                className={s.bbCheckbox}
              />
              <Label
                htmlFor="hide_for_external_users"
                className={s.bbCheckboxLabel}
                text={t("pages:workflows:form:actions:reply:replyPrivatelyTab:hideForNonBBUsers")}
              />
            </>
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default ReplyPrivatelyAction;
