import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import { AmPmType, WeekdayType } from "@store/notifications/types";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { IObjectOption, IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { ISavedReply } from "@store/replies/types";
import { IRequestState } from "@store/types";

export type WorkflowsActions = ActionType<typeof actions>;

export type WorkflowItemCategoryType =
  | "tag"
  | "archive"
  | "assign"
  | "sentiment"
  | "hide"
  | "email_alert"
  | "zendesk"
  | "reply";

export type WorkflowGroupType = "all" | "organize" | "analyze" | "protect" | "engage";

export type WorkflowGroupsAndCategoriesType = WorkflowGroupType | WorkflowItemCategoryType;

export type WorkflowItemLabelIdType =
  | "fully_managed"
  | "keyword_based"
  | "preset"
  | "time_frame_based"
  | "x_time"
  | "up_to_1_hour";

export interface IWorkflowItemLabel {
  id: WorkflowItemLabelIdType;
  label: string;
}

export interface ITimeFrame {
  period?: AmPmType;
  time?: string;
}

export interface ITimeFrameTrigger {
  start_time: ITimeFrame;
  end_time: ITimeFrame;
  week_days: WeekdayType[];
  timezone: string;
}

export type IWorkflowKeywordsTrigger = string[];
export type IWorkflowTrigger = {
  keyword_query?: IWorkflowKeywordsTrigger;
  time_frame?: ITimeFrameTrigger;
  filters?: IFilters;
};

export type IWorkflowSentimentAction = "positive" | "negative" | "neutral";
export type IWorkflowTagAction = string[];

export enum EmailAlertRangeEnum {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  DYNAMIC = "dynamic",
}

export interface IRiskIdentification {
  risked?: boolean;
  risk_lvl: "low" | "moderate" | "high" | "critical";
  impact_area?: string[];
  additional_instructions?: string;
}

export interface IWorkflowEmailAlertAction {
  range_type: EmailAlertRangeEnum;
  range_number?: number;
  is_single_email?: boolean;
  user_ids: string[];
  schedule?: {
    hour?: number;
    meridiem?: AmPmType;
    week_days?: WeekdayType[];
  };
  is_post_based?: boolean;
  is_managed?: boolean;
  risk_identification?: IRiskIdentification;
  threshold?: number;
}

export interface IWorkflowReplyAction {
  reply_template_id?: string;
  private_reply_template_id?: string;
  delay_private_reply?: number;
  reply_types?: ["private" | "public"];
  reply_only_first_private_message?: boolean;
  overwrite_workflow_name_listing?: boolean;
  hide_for_external_users?: boolean;
}

export type IWorkflowAction =
  | IWorkflowTagAction
  | IWorkflowSentimentAction
  | IWorkflowEmailAlertAction
  | IWorkflowReplyAction;

export interface IWorkflowItem {
  id: string;
  category: WorkflowItemCategoryType;
  title: string;
  description: string;
  example?: string;
  labels: IWorkflowItemLabel[];
  enabled: boolean;
  is_editable: boolean;
  show_enable_checkbox: boolean;
  trigger?: IWorkflowTrigger;
  action?: IWorkflowAction;
  email_alert?: IWorkflowEmailAlertAction;
  reply?: {
    reply_template?: ISavedReply;
    private_reply_template?: ISavedReply;
    delay_private_reply?: number;
    reply_types?: ["private" | "public"];
    reply_only_first_private_message?: boolean;
    overwrite_workflow_name_listing?: boolean;
    hide_for_external_users?: boolean;
  };
}

export interface IWorkflowCategory {
  id: string;
  label: string;
  ids: string[];
  sub_categories: IWorkflowCategory[];
  is_locked?: boolean;
  is_visible?: boolean;
}

export type IKeywordFilter = {
  filters: {
    keyword_query: IWorkflowKeywordsTrigger;
    keyword_query_in_translation: boolean;
    time_frame?: ITimeFrameTrigger;
  };
};

export interface IWorkflowItemPayload {
  id?: string;
  title: string;
  description: string;
  category: WorkflowItemCategoryType;
  sentiment?: IWorkflowSentimentAction;
  tags?: IWorkflowTagAction;
  enabled: boolean;
  trigger: IKeywordFilter;
  reply_template_id?: number;
}

export interface IGetWorkflowsResponse {
  items: IWorkflowItemPayload[];
  categories: IWorkflowCategory[];
  risk_identifications: IWorkflowRiskOptions;
}

export interface IGetWorkflowsResponseMapped {
  items: IWorkflowItem[];
  categories: IWorkflowCategory[];
  risk_identifications: IWorkflowRiskOptions;
}

export interface IWorkflowRiskOptions {
  risk_lvl: { id: IRiskIdentification["risk_lvl"]; label: string }[];
  impact_area: IObjectOption[];
}

export interface IWorkflowSavedReplyPayload {
  query?: string;
  preset_query?: string;
}

export interface IWorkflowsState {
  workflowItems: IWorkflowItem[];
  workflowCategories: IWorkflowCategory[];
  workflowRiskOptions: IWorkflowRiskOptions;
  workflowsSavedReplies: ISavedReply[];
  initialFetchDone: boolean;

  fetchingWorkflows: boolean;
  fetchedWorkflows: boolean;
  fetchingWorkflowsFailed: boolean;

  creatingWorkflow: boolean;
  createdWorkflow: boolean;
  creatingWorkflowFailed: boolean;

  editingWorkflow: boolean;
  editedWorkflow: boolean;
  editingWorkflowFailed: boolean;

  deletingWorkflow: boolean;
  deletedWorkflow: boolean;
  deletingWorkflowFailed: boolean;

  enablingWorkflow: boolean;
  enabledWorkflow: boolean;
  enablingWorkflowFailed: boolean;

  disablingWorkflow: boolean;
  disabledWorkflow: boolean;
  disablingWorkflowFailed: boolean;

  fetchingWorkflowsSavedReplies: boolean;
  fetchedWorkflowsSavedReplies: boolean;
  fetchingWorkflowsSavedRepliesFailed: boolean;

  matchingComments: IRequestState<{
    comments: IComment[];
    totalCount: number;
  }>;

  formDirty: boolean;
}
