import cn from "classnames/bind";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { Checkbox, ButtonPermissionGuard } from "@bbdevcrew/bb_ui_kit_fe";
import ReplySendButton from "./replySendButton";

import s from "./Reply.module.less";

import { IReplyBottomActions } from "./Reply.types";

const cx = cn.bind(s);

const ReplyBottomActions: FC<IReplyBottomActions> = ({
  comment,
  isPrivateReply,
  isReplyBtnLoading,
  isReplyBtnDisabled,
  replyMessageLength,
  maxReplyMessageLength,
  onInternalReplyClick,
  replyCapability,
}) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const showReplyButton =
    replyCapability?.send_capability?.capability !== "not_applicable" ||
    replyCapability?.request_capability?.capability !== "not_applicable";

  const [isAutoNavigation, setIsAutoNavigation] = useState(
    JSON.parse(localStorage.getItem("AutoNavigation") || "true"),
  );

  const isMobile = screens.md === false;

  const onAutoNavigationToggle = () => {
    setIsAutoNavigation(!isAutoNavigation);
    localStorage.setItem("AutoNavigation", (!isAutoNavigation).toString());
  };

  return (
    <div
      className={cx(s.bbReplyInputActions, s.bbReplyInputActionsBottom, {
        [s.bbReplyInputActionsMobile]: isMobile,
      })}
    >
      {!isMobile && (
        <div className={s.bbReplyInputActionsLeft}>
          {replyMessageLength >= maxReplyMessageLength && (
            <span className={s.bbReplyInputErrorMessage}>
              {t("components:reply:charLimitError", {
                amount: maxReplyMessageLength,
              })}
            </span>
          )}
          <span className={s.bbAutoNavigationToggle} onClick={onAutoNavigationToggle}>
            <Checkbox id={"auto-navigation"} checked={isAutoNavigation} _size="sm" />

            <span className={s.bbAutoNavigationTitle}>
              {t("components:reply:autoNavigationCheckbox")}
            </span>
          </span>
        </div>
      )}
      {showReplyButton && (
        <ButtonPermissionGuard
          message={comment && comment.actions.reply_tooltip}
          hasPermission={!!comment ? comment.actions.can_reply : true}
        >
          <ReplySendButton
            onInternalReplyClick={onInternalReplyClick}
            isReplyBtnDisabled={isReplyBtnDisabled}
            isReplyBtnLoading={isReplyBtnLoading}
            isPrivateReply={isPrivateReply}
            replyCapability={replyCapability}
          />
        </ButtonPermissionGuard>
      )}
    </div>
  );
};

export default ReplyBottomActions;
