import React, { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import WorkflowTypeCard from "./WorkflowTypeCard";
import { Button, RadioGroup, ChevronRightIcon, WarningIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { workflowCategoriesSelector } from "@store/workflows/selectors";

import { IStep1Props } from "./types";
import { WorkflowItemCategoryType } from "@store/workflows/types";

import s from "./Step.module.less";

import { WorkflowCategoryIconMap } from "../menu/helpers";

const Step1: FC<IStep1Props> = ({
  setCurrentStep,
  selectedWorkflowType,
  setSelectedWorkflowType,
}) => {
  const { t } = useTranslation();
  const [hasWorkflowTypeRequiredError, setHasWorkflowTypeRequiredError] = useState(false);

  const workflowCategories = useSelector(workflowCategoriesSelector);

  const onCreateWorkflowClick = () => {
    if (selectedWorkflowType === undefined) {
      setHasWorkflowTypeRequiredError(true);
    } else {
      setHasWorkflowTypeRequiredError(false);
      setSelectedWorkflowType(selectedWorkflowType);
      setCurrentStep(1);
    }
  };

  const onWorkflowTypeClick = (value: WorkflowItemCategoryType) => {
    setHasWorkflowTypeRequiredError(false);
    setSelectedWorkflowType(value);
  };

  return (
    <div className={s.bbWorkflowCreationStep} data-cy="workflow-creation-step1">
      <h2 className={s.bbWorkflowModalTitle}>
        {t("pages:workflows:workflowCreationModal:step1:title")}
      </h2>
      {hasWorkflowTypeRequiredError && (
        <div className={s.bbWorkflowTypeError}>
          <WarningIcon />
          {t("pages:workflows:workflowCreationModal:step1:requiredError")}
        </div>
      )}
      <div>
        <RadioGroup
          value={selectedWorkflowType}
          onChange={value => onWorkflowTypeClick(value as WorkflowItemCategoryType)}
          className={s.bbWorkflowSectionRadio}
        >
          {workflowCategories
            .filter(category => {
              return (
                category.id !== "all" &&
                !!category?.is_visible &&
                !!category?.sub_categories?.some(subCategory => !!subCategory?.is_visible)
              );
            })
            .map(category => {
              const Icon = WorkflowCategoryIconMap[category.id];
              return (
                <Fragment key={category.id}>
                  <div className={s.bbWorkflowCreationSectionTitle}>
                    {Icon && <Icon />}
                    {t(`pages:workflows:workflowCreationModal:workflowTypes:${category.id}`)}
                  </div>
                  {category?.sub_categories
                    ?.filter(
                      subCategory => subCategory.id !== "assign" && !!subCategory?.is_visible,
                    )
                    .map(subCategory => (
                      <WorkflowTypeCard
                        key={subCategory.id}
                        type={subCategory.id as WorkflowItemCategoryType}
                        selected={selectedWorkflowType === subCategory.id}
                        showUpgrade={!!subCategory.is_locked}
                      />
                    ))}
                </Fragment>
              );
            })}
        </RadioGroup>
      </div>
      <div className={s.bbWorkflowCreationStepFooter}>
        <Button
          _type="primary"
          onClick={onCreateWorkflowClick}
          className={s.bbWorkflowCreationStepBtn}
          data-cy="workflow-creation-step1-btn"
        >
          {t("pages:workflows:workflowCreationModal:step1:okBtn")}
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default Step1;
