export const MIN_MINUTES = 3;
export const MAX_MINUTES = 600;

export const getActionFormItemName = (name: string) => ["action", name];

export const isPrivateReplyWorkflow = (messageTypesValue: string) => {
  return messageTypesValue === "private_messages" || messageTypesValue === "replies_to_stories";
};

export const isPublicToPrivateReplyWorkflow = (
  messageTypesValue: string,
  platformTypesValue?: string[],
) => {
  return (
    (messageTypesValue === "comments" || messageTypesValue === "ad_comments") &&
    platformTypesValue?.every(p => ["facebook", "instagram"].includes(p))
  );
};
