import React, { FC, useContext, useState } from "react";
import classNames from "classnames";

import { Grid } from "antd";
import AIPromptList from "./AIPromptList";
import { Modal } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AIPromptsModal.module.less";

import { AIPromptViewType, IAIPromptModalProps } from "../types";
import AIPromptsContext from "../context/AIPromptsContext";
import { IAIPrompt } from "@store/repliesAIPrompts/types";

const AIPromptsModal: FC<IAIPromptModalProps> = ({
  aIPrompts,
  aIPromptsForm: AIPromptsForm,
  onCancel,
}) => {
  const screens = Grid.useBreakpoint();
  const [view, setView] = useState<AIPromptViewType>("list");
  const [editItem, setEditItem] = useState<IAIPrompt>();

  const isMobile = screens.md === false;

  const { getAIPromptSuggestion } = useContext(AIPromptsContext);

  const onPromptClick = (promptId?: string) => {
    getAIPromptSuggestion(promptId);
    onCancel && onCancel();
  };

  const onViewChange = (_view: AIPromptViewType, _editItem?: IAIPrompt) => {
    setView(_view);

    if (_editItem) setEditItem(_editItem);
  };

  const handleCancel = () => {
    onViewChange("list");
  };

  return (
    <Modal
      centered
      hideFooter
      width={600}
      hideCloseIcon
      hideHeader={true}
      onCancel={onCancel}
      open={true}
      classes={{
        root: classNames(s.bbAiPromptModal, {
          [s.bbAiPromptModalMobile]: isMobile,
        }),
        body: s.bbAiPromptModalBody,
      }}
    >
      {view === "list" && (
        <AIPromptList
          aIPrompts={aIPrompts}
          onViewChange={onViewChange}
          onPromptClick={onPromptClick}
        />
      )}
      {view === "create" && <AIPromptsForm mode="create" onCancel={handleCancel} />}
      {view === "edit" && <AIPromptsForm mode="edit" editItem={editItem} onCancel={handleCancel} />}
    </Modal>
  );
};

export default AIPromptsModal;
