import * as actions from "./actions";
import { ActionType } from "typesafe-actions";
import { IModuleFeature } from "@bbdevcrew/bb_ui_kit_fe";

export type ModulesActions = ActionType<typeof actions>;

export type DashboardComponentType =
  | "explorer_overview_care"
  | "explorer_overview_safety"
  | "explorer_overview_sentiment"
  | "explorer_overview_conversations"
  | "explorer_overview_platform"
  | "explorer_overview_top_keywords"
  | "explorer_overview_top_keyword_phrases"
  | "explorer_overview_top_emojis"
  | "explorer_overview_top_languages"
  | "explorer_overview_top_influencers"
  | "explorer_agent_performance"
  | "explorer_overview_message_types"
  | "safety_breakdown"
  | "hidden_breakdown"
  | "review_widgets"
  | "top_tags"
  | "action_summary_stats"
  | "hidden_comments"
  | "hidden_timeline"
  | "sentiment_timeline"
  | "sentiment_breakdown"
  | "sentiment_extended";

export type IModuleFeatureType =
  | "report-alerts"
  | "topics"
  | "listen-overview"
  | "listen-sentiment_details"
  | "listen-reviews"
  | "listen-agent_performance"
  | "listen-conversation_list"
  | "listen_more-trending_hashtags"
  | "listen_more-listening_sources"
  | "report-posts"
  | "report-compare"
  | "report-profiles";

export interface IGetModuleSectionsResponse {
  items: IModuleFeature<DashboardComponentType>[];
}

export const SidebarAvailableSections = "insights";

export type IAvailableSectionsType = "insights";

export interface IModulesState {
  modules: {
    [key in IAvailableSectionsType]?: IModuleFeature<DashboardComponentType>[];
  };

  fetchingModuleSectionsList: boolean;
  fetchedModuleSectionsList: boolean;
  fetchedModuleSectionsListFail: boolean;
}
