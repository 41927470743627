import { createReducer } from "typesafe-actions";
import produce from "immer";
import * as actions from "./actions";
import { AccountsActionsType, IAccountState } from "./types";
import { AIModalStateEnum } from "@store/aiInsights/types";

const initialState: IAccountState = {
  deletingAccount: false,
  deletedAccount: false,
  deletedAccountFail: false,

  isAIOnboardingAvailable: false,
  aiModalState: AIModalStateEnum.Closed,
  currentStep: 0,
  isInitialState: false,
  id: "",
  fields: {},
  messages: [],
  initial_message: undefined,
  fetchingFields: false,
  fetchedFields: false,
  fetchedFailFields: false,
};

export const accountsReducer = createReducer<IAccountState, AccountsActionsType>(initialState)
  .handleAction(actions.deleteAccount, state => {
    return produce(state, draft => {
      draft.deletingAccount = true;
      draft.deletedAccount = false;
      draft.deletedAccountFail = false;
    });
  })
  .handleAction(actions.deleteAccountSuccess, state => {
    return produce(state, draft => {
      draft.deletingAccount = false;
      draft.deletedAccount = true;
      draft.deletedAccountFail = false;
    });
  })
  .handleAction(actions.deleteAccountFailure, state => {
    return produce(state, draft => {
      draft.deletingAccount = false;
      draft.deletedAccount = false;
      draft.deletedAccountFail = true;
    });
  })
  .handleAction(actions.checkOnboardingAISuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.isAIOnboardingAvailable = action.payload.available;
      draft.id = action.payload.message_id;
      draft.aiModalState = action.payload.available
        ? AIModalStateEnum.Expanded
        : initialState.aiModalState;
    });
  })
  .handleAction(actions.toggleAIOnboardingAction, (state, action) => {
    if (!action.payload) {
      return initialState;
    }
    return produce(state, draft => {
      draft.aiModalState = action.payload;
    });
  })
  .handleAction(actions.getOnboardingFieldsAction, state => {
    return produce(state, draft => {
      draft.fetchingFields = true;
      draft.fetchedFields = false;
      draft.fetchedFailFields = false;
    });
  })
  .handleAction(actions.getOnboardingFieldsSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.fetchingFields = false;
      draft.fetchedFields = true;
      draft.fetchedFailFields = false;
      draft.isInitialState = !!action.payload.isInitialState;
      draft.fields = { ...draft.fields, ...(action.payload.fields || {}) };
      draft.id = action.payload.message_id;
      draft.initial_message = action.payload.initial_message;

      // Determine the current step
      if (action.payload.isInitialState) {
        draft.messages = [...(action.payload.messages || [])];

        if (action.payload.fields.hiding_config) {
          draft.currentStep = 4;
        } else if (action.payload.fields.brand_voice) {
          draft.currentStep = 3;
        } else if (action.payload.fields.industry) {
          draft.currentStep = 2;
        } else if (action.payload.fields.faq_urls) {
          draft.currentStep = 1.5;
        } else {
          draft.currentStep = 1;
        }
      } else if (action.payload.goToNext) {
        draft.currentStep = draft.currentStep === 1.5 ? 2 : draft.currentStep + 1;
        draft.messages = [...(action.payload.messages || [])];
      } else {
        draft.messages = [...draft.messages, ...(action.payload.messages || [])];
      }
    });
  })
  .handleAction(actions.getOnboardingFieldsFailureAction, state => {
    return produce(state, draft => {
      draft.fetchingFields = false;
      draft.fetchedFields = false;
      draft.fetchedFailFields = true;
    });
  })
  .handleAction(actions.postOnboardingFieldsAction, (state, action) => {
    return produce(state, draft => {
      draft.fetchingFields = true;
      draft.fetchedFields = false;
      draft.fetchedFailFields = false;
      draft.fields = { ...draft.fields, ...action.payload.fields };
    });
  })
  .handleAction(actions.postOnboardingFieldsFailureAction, state => {
    return produce(state, draft => {
      draft.fetchingFields = false;
      draft.fetchedFields = false;
      draft.fetchedFailFields = true;
    });
  });
